@import 'vendor/bootstrap/bootstrap';
@import '~uikit/src/scss/variables';
@import '~uikit/src/scss/uikit';
@import 'vendor/fontawesome/fontawesome';
@import 'vendor/slick-slider/slick',
'vendor/slick-slider/slick-theme';

@import "variables/sizes",
"variables/colors",
"variables/fonts";

@import "global/global",
"global/form/checkbox",
"global/form/slider";
@import "layout/header",
"layout/nav",
"layout/footer";

@import "pages/home",
"pages/product",
"products/categories",
"products/product-list";

@import 'checkout/cart';

$font1: 'Roboto', sans-serif;
$font2: 'Roboto', sans-serif;
$font3: 'Roboto', sans-serif;
$font4: 'Roboto', sans-serif;
$font5: 'Roboto', sans-serif;
$font6: 'Roboto', sans-serif;


html, body {
    font-family: $font1;
    color: $black;
    font-size: 20px;
    line-height: 30px;
    /*text-align: justify;*/
    overflow-x: hidden;
    font-weight: 300;
}

.searchpage-content .search-field-input input {border-radius: 0px;}

.webshop-page-main {
    background: url('/images/background-large.jpg') no-repeat 50% 50%;
    background-size: cover;
}

.btn {
    border-radius: 0px;
}

.filterspan {
    font-family: $font1;
}

.cart-content-section *, .checkout-page-form * {
    color: $darkgrey-v2;
}

.cart-content-section h2 {
    color: $brown-v1;
}

.fa-square:before, input[type=checkbox] + label:before,
.fa-circle:before, input[type=radio] + label:before {
    /*color: $brown-v1;*/
    color: #C4C4C4;
}

.algemene-voorwaarden-header, .disclaimer-header, .privacy-policy-header, .contact-header {
    /* background: url('/images/bg.jpg') no-repeat 50% 50%;*/
    /*background: url('/images/fabric-textured-background.jpg') no-repeat 50% 50%;*/
    background: #74C6C7;
    background-size: cover;
}
/*
.algemene-voorwaarden-header > div, .disclaimer-header > div, .privacy-policy-header > div, .contact-header > div {
    padding-left: 0px;
    padding-right: 0px;
}*/

.algemene-voorwaarden-header h1, .disclaimer-header h1, .privacy-policy-header h1, .contact-header h1 {
    font-family: $font5;
    font-weight: normal;
    font-style: normal;
    color: $brown-v2;
    font-size: 38px;
    text-transform: uppercase;
    letter-spacing: 2px;
    text-align: left;
}

.algemene-voorwaarden-main, .disclaimer-main, .privacy-policy-main, .contact-main {
    padding: 70px 20px;
    box-sizing:border-box;
}

.algemene-voorwaarden-main h1, .disclaimer-main h1, .privacy-policy-main h1, .contact-main h1 {
    font-family: $font5;
    font-weight: normal;
    font-style: normal;
    color: $brown-v1;
    font-size: 38px;
    text-transform: uppercase;
    letter-spacing: 2px;
    text-align: left;
}

.privacy-policy-main h3 {
    font-family: $font5;
    font-weight: normal;
    font-style: normal;
    color: $brown-v2;
    font-size: 24px;
    text-transform: uppercase;
    letter-spacing: 2px;
    text-align: left;
}



.algemene-voorwaarden-main p, .disclaimer-main p, .privacy-policy-main p, .privacy-policy-main th, .privacy-policy-main td {
    color: $darkgrey-v2;
}

.header-icons {
    position: relative;
    z-index: 99;
}

.header-icons img:hover {
    /*filter: invert(81%) sepia(8%) saturate(813%) hue-rotate(349deg) brightness(91%) contrast(89%);*/
    /*filter: invert(84%) sepia(12%) saturate(504%) hue-rotate(349deg) brightness(86%) contrast(93%);*/
    /*filter: invert(98%) sepia(2%) saturate(7155%) hue-rotate(307deg) brightness(88%) contrast(78%);*/
    /*filter: invert(80%) sepia(1%) saturate(5760%) hue-rotate(329deg) brightness(89%) contrast(106%);*/
    filter: brightness(0) invert(1);
}

strong, b {
    font-family: $font2;
}

.alert {
    margin-bottom: 0;
}

.pastel-3-border-bottom {
    border-bottom: 1px solid $pastel-3-accent;
}

h2 {
    /*font-family: $font5;
    font-weight: normal;
    font-style: normal;
    color: $brown-v1;
    font-size: 38px;
    text-transform: uppercase;
    letter-spacing: 2px;
    text-align: left;*/
    font-family: 'Roboto';
    font-style: normal;
    font-weight: normal;
    font-size: 36px;
    line-height: 43px;
    color: #74C6C7;
}

header {
    position: static;
}


.header {
    background: url();
}

/*.over-ons-header {
    background: url('/images/textured-background.jpg') no-repeat 50% 50%;
    background-size: cover;
}*/


.home-header {
    background: url('/images/Cornet-Seating-Systems.jpg') no-repeat 50% 50%;
    background-size: cover;
}

.home-header > div {
    padding-top: 145px;
    padding-bottom: 120px;
}

.home-header .col-12 > div {
    position: relative;
    z-index: 0;
}

.home-header h1 {
    font-family: $font1;
    font-style: normal;
    font-weight: 300;
    font-size: 64px;
    line-height: 76px;
    color: $white;
    text-transform: none;
    text-align: left;
}
.home-header h1 span {
    font-style: normal;
    font-weight: bold;
    font-size: 64px;
    line-height: 76px;
    text-transform: uppercase;
}

.home-header p {
    max-width: 630px;
    font-family: $font1;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 29px;
    color: $white;
}

.home-header-buttons {
    max-width: 500px;
    margin-top: 10px;
}

.home-header-buttons > div:nth-child(1) a {
    width: 233px;
    height: 45px;
    border: 2px solid $white;
    display: inline-block;
    color: $black;
    background: $white;
    font-family: $font1;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 45px;
    text-align: center;
    transition: all 0.25s ease;
    -webkit-transition: all 0.25s ease;
}

.home-header-buttons > div:nth-child(1) a:hover,
.home-header-buttons > div:nth-child(1) a:focus {
    color: $white;
    background: $black;
    text-decoration: none;
}

.home-header-buttons > div:nth-child(2) a {
    width: 233px;
    height: 45px;
    border: 2px solid $white;
    display: inline-block;
    color: $white;
    font-family: $font1;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 45px;
    text-align: center;
    transition: all 0.25s ease;
    -webkit-transition: all 0.25s ease;
}

.home-header-buttons > div:nth-child(2) a:hover,
.home-header-buttons > div:nth-child(2) a:focus {
    color: $black;
    background: $white;
    text-decoration: none;
}

/*.home-header .col-12 > div::before {
        content: "";
        display: inline-block;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(0,0,0,0.36);
        z-index: 1;
    }*/

.home-header img {
    max-height: 670px;
    object-fit: cover;
}


.mini-cart a {
    background: $main-color;
    padding: 10px;
    box-shadow: 0px 0px 8px 3px rgba(23, 158, 172, 0.26);
    display: inline-block;
    width: 45px;
    height: 45px;
    box-sizing: border-box;
    line-height: 25px;
}

header .search-field {
    margin-right: 30px;
}


ul.menu-main-menu > li > a {
    font-family: $font1;
    font-style: normal;
    font-weight: 300;
    font-size: 20px;
    line-height: 24px;
    text-transform: uppercase;
    color: $black !important;
}

ul.menu-main-menu > li > a:hover,
ul.menu-main-menu > li > a:focus {
    color: $main-color !important;
}

.contact-header img {
    object-position: 50% 80%;
}

.over-ons-header .col-12, .contact-header .col-12, .algemene-voorwaarden-header .col-12, .disclaimer-header .col-12, .privacy-policy-header .col-12 {
    position: relative;
    z-index: 0;
}

.over-ons-header {
    background: #74C6C7;
}

/*
.over-ons-header .col-12::before, .contact-header .col-12::before, .algemene-voorwaarden-header .col-12::before, .disclaimer-header .col-12::before, .privacy-policy-header .col-12::before {
    content: "";
    display: inline-block;
    position: absolute;
    top: 0;
    left: 15px;
    right: 15px;
    bottom: 0;
    background: rgba(0,0,0,0.36);
    z-index: 1;
}*/


.over-ons-header img, .contact-header img, .algemene-voorwaarden-header img, .disclaimer-header img, .privacy-policy-header img {
    max-height: 670px;
    object-fit: cover;
}


.header-home > div.py-4 {
    padding-top: 40px !important;
    padding-bottom: 40px !important;
}

.info-header {
    /* background: url('/images/bg.jpg') no-repeat 50% 50%;*/
    background: url('/images/textured-background.jpg') no-repeat 50% 50%;
    background-size: cover;
}

.info-header .search-field {
    margin-bottom: -140px;
    position: relative;
    z-index: 9;
}

.header-home > div {
    padding-top: 0px !important;
}

/*.home-header-logo {
    margin-bottom: -230px;
    position: relative;
    z-index: 9;
    top: -40px;
}*/

.home-header-logo > div {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-align-content: center;
    align-content: center;
    -webkit-align-items: center;
    align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
}

.header-icons .search-field {
    margin-left: 45px;
    margin-right: 15px;
}

.header-icons .menutext {
    font-family: $font2;
    font-size: 22px;
    line-height: 38px;
    color: $brown-v1;
    text-transform: uppercase;
    position: relative;
    top: 2px;
}

.header-icons .menutext:hover,
.header-icons .menutext:focus {
    color: $brown-v2;
    text-decoration: none;
}

.home-usps {
    /*background: url('/images/bg.jpg') no-repeat 50% 50%;*/
    /*background: url('/images/fabric-textured-background.jpg') no-repeat 50% 50%;
    background-size: cover;*/
    /*background: linear-gradient(90.06deg, #74C6C7 0.05%, #1C9596 99.98%);*/
    background: #2B2B2B;
}

.home-usps h2 {
    /*background: -webkit-linear-gradient(#f0e9e3, #fff 75%);
    background: linear-gradient(#f0e9e3, #fff 75%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;*/
    font-family: $font2;
    font-size: 195.39px;
    line-height: 183.60px;
    letter-spacing: 20px;
    margin-left: -10px;
    color: rgba(255, 255, 255, 0.039);
}

.home-usps-col1, .home-categories-title, .home-merken h2, .webshop-categories-title {
    animation-duration: 1.0s;
}

.webshop-categories .txt {
    text-align: center;
}

.home-usps ul, .over-ons-usps ul, .categorie-top-usps ul, .product-top-usps ul {text-align: center;list-style: none;/*margin-right: -60px;padding-right: 40px;*/

    margin-top: 22px;
    margin-bottom: 22px;
    margin-right: -40px;
    margin-left: 0px;
    padding-left: 0px;
}

.home-usps ul li, .over-ons-usps ul li, .categorie-top-usps ul li, .product-top-usps ul li {
    padding-right: 40px;
    font-family: $font1;
    color: $white;
    display: inline-block;
    padding-left: 28px;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 24px;

}

.home-usps ul li a, .over-ons-usps ul li a, .categorie-top-usps ul li a, .product-top-usps ul li a {
    font-family: $font1;
    color: $white;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 24px;
}

.home-usps ul li a:hover, .over-ons-usps ul li a:hover, .categorie-top-usps ul li a:hover, .product-top-usps ul li a:hover,
.home-usps ul li a:focus, .over-ons-usps ul li a:focus, .categorie-top-usps ul li a:focus, .product-top-usps ul li a:focus {
    color: #74C6C7;
    text-decoration: none;
}

.home-usps ul li, .over-ons-usps ul li, .categorie-top-usps ul li, .product-top-usps ul li {
    position: relative;
}

.home-usps ul li::before, .over-ons-usps ul li::before, .categorie-top-usps ul li::before, .product-top-usps ul li::before {
    position: absolute;
    top: 50%;
    left: 0%;
    -webkit-transform: translate(0%, -50%);
    -moz-transform: translate(0%, -50%);
    -ms-transform: translate(0%, -50%);
    -o-transform: translate(0%, -50%);
    transform: translate(0%, -50%);
}

.home-usps ul li:nth-child(1)::before,
.over-ons-usps ul li:nth-child(1)::before,
.categorie-top-usps ul li:nth-child(1)::before,
.product-top-usps ul li:nth-child(1)::before {
    content: '';
    background: url('/images/check-mark.svg');
    display: inline-block;
    width: 16px;
    height: 16px;
}
.home-usps ul li:nth-child(2)::before,
.over-ons-usps ul li:nth-child(2)::before,
.categorie-top-usps ul li:nth-child(2)::before,
.product-top-usps ul li:nth-child(2)::before {
    content: '';
    background: url('/images/phone.svg');
    display: inline-block;
    width: 16px;
    height: 16px;
}
.home-usps ul li:nth-child(3)::before,
.over-ons-usps ul li:nth-child(3)::before,
.categorie-top-usps ul li:nth-child(3)::before,
.product-top-usps ul li:nth-child(3)::before {
    content: '';
    background: url('/images/pointer.svg');
    display: inline-block;
    width: 16px;
    height: 16px;
}


.home-main {
    background: url('/images/home-main-bg.jpg') no-repeat 50% 50%;
    background-size: cover;
}

.home-main > div > div {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-align-content: center;
    align-content: center;
    -webkit-align-items: center;
    align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
}

.home-main img {
    max-height: 500px;
    object-fit: contain;
}

.home-main h2 {
    font-family: $font1;
    font-style: normal;
    font-weight: 300;
    font-size: 36px;
    line-height: 43px;
    color: $main-color;
}
.home-main h2 span {
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    line-height: 43px;
}

.home-main p {
    font-family: $font1;
    font-style: normal;
    font-weight: 300;
    font-size: 20px;
    line-height: 30px;
    color: $black;
    max-width: 560px;
}

.home-main-bttn {
    width: 233px;
    height: 45px;
    border: 2px solid $black;
    display: inline-block;
    color: $black;
    font-family: $font1;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 45px;
    text-align: center;
    transition: all 0.25s ease;
    -webkit-transition: all 0.25s ease;
    margin-top: 10px;
}

.home-main-bttn:hover,
.home-main-bttn:focus {
    color: $white;
    background: $black;
    text-decoration: none;
}


.home-main > div {
    padding-top: 85px;
    padding-bottom: 78px;
}


/*.home-popular-products {
    background: linear-gradient(180deg, #74C6C7 0%, #1C9596 100%);
}*/

.home-popular-products {
    background: linear-gradient(180deg, #F0F0F0 0%, rgba(240, 240, 240, 0) 100%);
}

.home-popular-products > div {
    padding-top: 70px;
    padding-bottom: 70px;
}

.home-popular-products > div > div:nth-child(1) {
    padding-bottom: 40px;
}

.home-popular-products > div > div:nth-child(1) > div:nth-child(2) {
    text-align: right;
}

.home-popular-products > div.container > div.row:nth-child(2) {
    position: relative;
}

.home-popular-products > div.container > div.row:nth-child(2)::before {
    content: '';
    display: inline-block;
    position: absolute;
    top: -50px;
    /*left: 50%;*/
    right: 15px;
    -webkit-transform: translate(0%, -50%);
    -moz-transform: translate(0%, -50%);
    -ms-transform: translate(0%, -50%);
    -o-transform: translate(0%, -50%);
    transform: translate(0%, -50%);
    background: url('/images/union.svg') no-repeat 50% 50%;
    width: 100%;
    height: 100%;
    max-width: 1021px;
    max-height: 157px;
}

.full-offer {
    font-family: $font1;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    text-align: right;
    text-decoration-line: underline;
    color: $black;
    position: relative;
    z-index: 2;
}

.full-offer:hover,
.full-offer:focus {
    color: #74c6c7;
}

.home-popular-products h2 {
    font-family: $font1;
    font-style: normal;
    font-weight: 300;
    font-size: 36px;
    line-height: 43px;
    color: $black;
    text-transform: none;
}

.home-popular-products h2 span {
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    line-height: 43px;
}

.home-popular-products-slider-inner .image-container {
    position: relative;
    z-index: 0;
}


.home-popular-products-slider-inner .image-container .productlink {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 99;
}

.home-popular-products-slider-inner .txt-container {
    background: $black;
    padding: 20px 25px;
    box-sizing: border-box;
    position: relative;
    z-index: 0;
}

.home-popular-products-slider-inner .txt-container .productlink {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 99;
}


.home-popular-products-nav {
    display: none;
}

.page-contact h2 {
    font-family: $font1;
    font-style: normal;
    font-weight: 300;
    font-size: 36px;
    line-height: 43px;
    color: $main-color;
}

.page-contact h2 span {
    font-weight: bold;
}

/*
    .home-popular-products-slider-inner .txt-container .col-10 {
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-wrap: wrap;
        flex-wrap: wrap;
        -webkit-align-content: center;
        align-content: center;
        -webkit-align-items: center;
        align-items: center;
        justify-content: center;
        -webkit-justify-content: center;
    }*/

.home-popular-products-slider-inner .txt-container .col-10 > div {
    flex: 0 1 auto;
    text-align: left;
}

.home-popular-products-slider-inner .txt-container .col-2 {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-align-content: center;
    align-content: center;
    -webkit-align-items: center;
    align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
}

.home-popular-products-slider-inner .txt-container .col-2 img {
    min-width: 30px;
}

.home-popular-products-slider-inner .txt-container .title {
    font-family: $font1;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 29px;
    color: $white;
}

.home-popular-products-slider-inner .txt-container .price {
    font-family: $font1;
    font-style: normal;
    font-weight: 300;
    font-size: 20px;
    line-height: 29px;
    color: $white;
}

.home-popular-products-slider-inner > ul {
    margin-left: -20px;
}

.home-popular-products-slider-inner > ul > li {
    padding-left: 20px;
}

.home-popular-products-slider-inner .image-container img {
    width: 100%;
    object-fit: cover;
}


.home-categories {
    background: $main-color;
    text-align: center;
}

.home-categories ul {
    list-style: none;
    margin-left: -100px;
    margin-top: 35px;
    margin-bottom: 35px;
}

.home-categories ul li {
    display: inline-block;
    font-family: $font1;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 30px;
    text-transform: uppercase;
    color: $white;
    padding-left: 100px;
}

.home-categories ul li a {
    color: $white;
}

.home-categories ul li a:hover, .home-categories ul li a:focus {
    color: #2B2B2B;
    text-decoration: none;
}


.home-unieke-collectie-homewear {
    /*background: url('/images/bg.jpg') no-repeat 50% 50%;*/
    background: url('/images/fabric-textured-background.jpg') no-repeat 50% 50%;
    background-size: cover;
    padding-top: 150px;
    padding-bottom: 100px;
}

.home-unieke-collectie-homewear img {
    position: absolute;
    top: 50%;
    left: 15px;
    -webkit-transform: translate(0%, -50%);
    -moz-transform: translate(0%, -50%);
    -ms-transform: translate(0%, -50%);
    -o-transform: translate(0%, -50%);
    transform: translate(0%, -50%);
    z-index: 2;
    max-height: 662px;
    max-width: 557px;
    object-fit: cover;
    object-position: 25% 50%;
}

.home-unieke-collectie-homewear-col2 {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-align-content: center;
    align-content: center;
    -webkit-align-items: center;
    align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
}

.home-unieke-collectie-homewear-col2 > div {
    max-width: 466px;
    padding: 20px;
    box-sizing: content-box;
}

.home-bottom {
    position: relative;
}

.home-bottom::before {
    position: absolute;
    display: inline-block;
    content: "";
    background: url('/images/union2.svg') no-repeat 0% 50%;
    left:0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    max-width: 128px;
    max-height: 613px;
    object-fit: contain;
}

.home-bottom > div {
    padding-top: 90px;
}

.home-bottom .col-md-6 > div > img {
    max-height: 500px;
}


.home-categories-section {
    padding-top: 90px;
    padding-bottom: 200px;
}


.home-bottom > div > div {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-align-content: center;
    align-content: center;
    -webkit-align-items: center;
    align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
}

.home-bottom h2 {
    font-family: $font1;
    font-style: normal;
    font-weight: 300;
    font-size: 36px;
    line-height: 43px;
    color: $main-color;
}

.home-bottom h2 span {
    font-weight: bold;
}

.home-bottom p {

    font-family: $font1;
    font-style: normal;
    font-weight: 300;
    font-size: 20px;
    line-height: 30px;
    color: $black;

}

.home-bottom-bttn {
    width: 233px;
    height: 45px;
    border: 2px solid $main-color;
    display: inline-block;
    color: $main-color;
    font-family: $font1;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 45px;
    text-align: center;
    transition: all 0.25s ease;
    -webkit-transition: all 0.25s ease;
    margin-top: 10px;
}

.home-bottom-bttn:hover,
.home-bottom-bttn:focus {
    background: $main-color;
    color: $white;
    text-decoration: none;
}


.home-main-bttn-green {
    width: 233px;
    height: 45px;
    border: 2px solid $main-color;
    display: inline-block;
    color: $main-color;
    font-family: $font1;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 45px;
    text-align: center;
    transition: all 0.25s ease;
    -webkit-transition: all 0.25s ease;
    margin-top: 10px;
}

.home-main-bttn-green:hover,
.home-main-bttn-green:focus {
    background: $main-color;
    color: $white;
    text-decoration: none;
}


.home-logos > div {
    padding-top: 60px;
    padding-bottom: 90px;
}

.home-logos > div > div > div {

    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-align-content: center;
    align-content: center;
    -webkit-align-items: center;
    align-items: center;
    justify-content: space-evenly;
    -webkit-justify-content: space-evenly;

}


.home-logos img {
    width: auto !important;
}

.home-categories-title h2, .webshop-categories-title h2 {
    background: -webkit-linear-gradient(#f0e9e3, #fff 75%);
    background: linear-gradient(#f0e9e3, #fff 75%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: "Montserrat-Bold", sans-serif;
    font-size: 195.39px;
    line-height: 183.60px;
    letter-spacing: 20px;
    text-align: right;
    margin-bottom: 0px;
}

.webshop-categories-title h2 {
    background: -webkit-linear-gradient(#aaa, #eee 75%);
    background: linear-gradient(#aaa, #eee 75%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: "Montserrat-Bold", sans-serif;
    font-size: 195.39px;
    line-height: 183.60px;
    letter-spacing: 20px;
    text-align: right;
    margin-bottom: 0px;
}

.home-categories-title + .container {
    margin-top: -50px;
    padding-left: 0px;
    padding-right: 0px;
}

.webshop-categories-title + .container {
    margin-top: -75px;
}

.home-categories .brown-bg a, .webshop-categories .brown-bg a {
    width: 100%;
    height: 100%;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-align-content: center;
    align-content: center;
    -webkit-align-items: center;
    align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
    flex-direction: column;
    -webkit-flex-direction: column;
    background: $brown-v1;
    color: $darkgrey-v1;
    font-family: $font5;
    font-weight: normal;
    font-style: normal;
    font-size: 36px;
    line-height: 36.65px;
    text-transform: uppercase;
    transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
    min-height: 250px;
    padding-top: 20px;
    padding-bottom: 20px;
    box-sizing: border-box;
}

.home-categories .brown-bg a:hover,
.home-categories .brown-bg a:focus,
.webshop-categories .brown-bg a:hover,
.webshop-categories .brown-bg a:focus {
    text-decoration: none;
    background: $darkgrey-v1;
    color: $brown-v2;
}

.home-categories .brown-bg .shop-btn,
.webshop-categories .brown-bg .shop-btn {
    font-family: $font1;
    font-size: 17px;
    color: $darkgrey-v1;
    border: 1px solid $darkgrey-v1;
    display: inline-block;
    padding: 0 24px;
    margin-top: 20px;
}

.home-categories .brown-bg a:hover .shop-btn,
.home-categories .brown-bg a:focus .shop-btn,
.webshop-categories .brown-bg a:hover .shop-btn,
.webshop-categories .brown-bg a:focus .shop-btn {
    background: $darkgrey-v1;
    color: $brown-v2;
    border: 1px solid $brown-v2;
}

.home-categories .brown-bg a .shop-btn:hover,
.home-categories .brown-bg a .shop-btn:focus,
.webshop-categories .brown-bg a .shop-btn:hover,
.webshop-categories .brown-bg a .shop-btn:focus {
    background-color: $brown-v2;
    color: $darkgrey-v1;
}

.home-categories .darkgrey-bg a,
.webshop-categories .darkgrey-bg a {
    width: 100%;
    height: 100%;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-align-content: center;
    align-content: center;
    -webkit-align-items: center;
    align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
    flex-direction: column;
    -webkit-flex-direction: column;
    background: $darkgrey-v1;
    color: $brown-v2;
    font-family: $font5;
    font-weight: normal;
    font-style: normal;
    font-size: 36px;
    line-height: 36.65px;
    text-transform: uppercase;
    transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
    min-height: 250px;
    padding-top: 20px;
    padding-bottom: 20px;
    box-sizing: border-box;
}

.home-categories .darkgrey-bg a:hover,
.home-categories .darkgrey-bg a:focus,
.webshop-categories .darkgrey-bg a:hover,
.webshop-categories .darkgrey-bg a:focus {
    text-decoration: none;
    background: $brown-v1;
    color: $darkgrey-v1;
}

.home-categories .darkgrey-bg .shop-btn,
.webshop-categories .darkgrey-bg .shop-btn {
    font-family: $font1;
    font-size: 17px;
    color: $brown-v2;
    border: 1px solid $brown-v2;
    display: inline-block;
    padding: 0 24px;
    margin-top: 20px;
}

.home-categories .darkgrey-bg a:hover .shop-btn,
.home-categories .darkgrey-bg a:focus .shop-btn,
.webshop-categories .darkgrey-bg a:hover .shop-btn,
.webshop-categories .darkgrey-bg a:focus .shop-btn {
    background: $brown-v1;
    color: $darkgrey-v1;
    border: 1px solid $darkgrey-v1;
}

.home-categories .darkgrey-bg a .shop-btn:hover,
.home-categories .darkgrey-bg a .shop-btn:focus,
.webshop-categories .darkgrey-bg a .shop-btn:hover,
.webshop-categories .darkgrey-bg a .shop-btn:focus {
    background-color: $darkgrey-v1;
    color: $white;
}
.webshop-categories .webshop-categories-img a {
    position: relative;
    z-index: 0;
    width: 100%;
    height: 100%;
    display: block;
}
.webshop-categories .webshop-categories-img a::before {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    content: "";
    width: 100%;
    height: 100%;
    display: inline-block;
}

.webshop-categories .webshop-categories-img a:hover::before {
    background: rgba(240, 233, 227, 0.2);
}

.webshop-categories-container {
    padding-bottom: 70px;
}

.checkout-page-form input[type=radio] + label:before {
    content: "\F0C8";
}

.checkout-page-form  input[type=radio]:checked + label:before {
    content: "\F14A";
}

.checkout-form.p-4, .payment-form .pl-4 {
    padding-left: 0px !important;
    padding-right: 0px !important;
}

.over-ons-categorieen {
    background: #74C6C7;
    /*padding-top: 40px;
    padding-bottom: 20px;*/
    padding-top: 0px;
    padding-bottom: 0px;
}

.over-ons-categorieen * {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 30px;
    text-transform: uppercase;
    color: #FFFFFF;
}

.over-ons-categorieen .col-12 {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-align-content: center;
    align-content: center;
    -webkit-align-items: center;
    align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
}

.over-ons-categorieen ul {
    list-style: none;
    margin-left: -100px;
}
.over-ons-categorieen ul li {
    display: inline-block;
    padding-left: 100px;
}

.over-ons-uniek-verdeler .over-ons-rust-col1 p:last-child a {
    margin-top: 10px;
}

.over-ons-uniek-verdeler a {
    width: 233px;
    height: 45px;
    line-height: 42px;
    display: inline-block;
    border: 2px solid #74C6C7;
    text-align: center;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    color: #74C6C7;
    transition: all 0.25s ease;
    -webkit-transition: all 0.25s ease;
}

.over-ons-uniek-verdeler a:hover,
.over-ons-uniek-verdeler a:focus {
    text-decoration: none;
    background-color: #74C6C7;
    color: #FFF;
}

.detail-tab-title {
    background: #74C6C7;
}

.detail-tab-title a {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    color: #FFFFFF;
    padding: 20px 45px;
    box-sizing: border-box;
    display: inline-block;
}

/*.detail-tab-title li.active a {
    background: #2B2B2B;
    font-weight: bold;
}*/
.detail-tab-title li a.active {
    background: #2B2B2B;
    font-weight: bold;
}

.detail-tab-title a:hover,
.detail-tab-title a:focus {
    background: #2B2B2B;
    text-decoration: none;
}

.detail-tab-title ul {
    max-width: 1280px;
    margin-left: auto;
    margin-right: auto;
}

.detail-tab-content {
    background: url('/images/tab-bg.jpg') no-repeat 50% 50%;
    background-size: cover;
}

.detail-tab-content h2 {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: normal;
    font-size: 36px;
    line-height: 43px;
    color: #000000;
}

.detail-tab-content p,
.detail-tab-content td,
.detail-tab-content li {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 24px;
    color: #2B2B2B;
}

.detail-tab-desc p:last-child,
.detail-tab-extra-info p:last-child,
.detail-tab-docs p:last-child {
    margin-bottom: 0px;
}

.detail-tab-desc *,
.detail-tab-extra-info *,
.detail-tab-docs * {
    max-width: 1280px;
    margin-left: auto;
    margin-right: auto;
}

.detail-tab-desc,
.detail-tab-extra-info,
.detail-tab-docs {
    padding: 45px 20px;
    box-sizing: border-box;
    min-height: 450px;
}

.detail-tab-docs a {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    color: #FFFFFF;
    padding: 15px 45px;
    box-sizing: border-box;
    display: inline-block;
    background: #74c6c7;
    transition: all 0.25s ease;
    -webkit-transition: all 0.25s ease;
    margin-top: 30px;
    min-width: 200px;
}

.detail-tab-docs a:hover,
.detail-tab-docs a:focus {
    background: #2B2B2B;
    text-decoration: none;
}


.slick-list {
    margin-left: 0;
    margin-right: 0;
}

/*
.slick-slider {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-align-content: center;
    align-content: center;
    -webkit-align-items: center;
    align-items: center;
    justify-content: flex-start;
    -webkit-justify-content: flex-start;
}
*/
/*
#product-detail .slick-list > div {
    max-width: 90px;
}
*/

/*.slick-list .slick-track {
    width: 90px;
}*/

.form-steps li a, .form-steps li span,
.form-steps li.enabled a, .form-steps li.enabled span, .form-steps li.current a, .form-steps li.current span {
    color: #000;
}

.home-podium-op-maat img {
    max-width: 552px;
    max-height: 500px;
}

.home-podium-op-maat {
    padding-bottom: 55px;
    position: relative;
}

.home-podium-op-maat::before {
    position: absolute;
    display: inline-block;
    content: "";
    background: url('/images/union3.svg') no-repeat 100% 50%;
    right: 0;
    top: -66%;
    bottom: 0;
    width: 100%;
    height: 100%;
    max-width: 128px;
    max-height: 537px;
    object-fit: contain;
}


.leadix-form input[type=radio] + label:before {
    display: none;
}

.mobile-menu-block.open .close-menu {
    display: none;
}

.mobile-menu-block ul {
    list-style: none;
}

.mobile-menu-block ul li {
    text-align: left;
}

.mobile-menu-block ul li + li {
    margin-top: 20px;
}

.mobile-menu-block ul li a {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 20px;
    line-height: 24px;
    text-transform: uppercase;
    color: #2B2B2B;
}

.mobile-menu-block ul li a:hover,
.mobile-menu-block ul li a:focus {
    color: #74C6C7;
    text-decoration: none;
}

.no-products-in-cart a {
    background: #74c6c7;
    border: 1px solid #74c6c7;
    color: #FFF;
}

.no-products-in-cart a:hover,
.no-products-in-cart a:focus {
    background: #2b2b2b;
    border: 1px solid #2b2b2b;
    color: #FFF;
}

.navback {
    padding-bottom: 20px;
}

.navback a {
    background: #74c6c7;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    color: #FFFFFF;
    padding: 0px 35px;
    display: inline-block;
    transition: all 0.25s ease;
    -webkit-transition: all 0.25s ease;
    height: 45px;
    line-height: 45px;
}

.navback a:hover,
.navback a:focus {
    background: #2B2B2B;
    text-decoration: none;
}


.slick-track {
    width: 100% !important;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-align-content: center;
    align-content: center;
    -webkit-align-items: center;
    align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
}

#product-detail .slick-slide {
    margin: 10px !important;
}

.offerte-op-maat .proceed-button button {

    font-family: 'Roboto';
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    color: #FFFFFF;
    padding: 0px 35px;
    display: inline-block;
    transition: all 0.25s ease;
    -webkit-transition: all 0.25s ease;
    height: 45px;
    line-height: 45px;
    background-color: #74C6C7;
    border: 1px solid #74C6C7;
}

.offerte-op-maat .proceed-button button:hover,
.offerte-op-maat .proceed-button button:focus {
    background: #2B2B2B;
}


@media (min-width: 640px) and (max-width: 1271px) {
    .home-popular-products-slider-inner .txt-container {
        min-height: 127px;
    }
}

@media (max-width: 991px) {

    .offerte-op-maat .cart-totals {
        padding-left: 0px !important;
    }

    .offerte-op-maat .cart-contents .cart-content-header {
        margin-left: 0px !important;
        margin-right: 0px !important;
    }

    .cart-page .no-products-in-cart {
        margin-top: 0 !important;
    }

    #product-detail .qty-add-to-cart {
        margin-bottom: 0 !important;
    }

    .page-contact h2, .page-offerteaanvraag h2 {
        font-size: 32px;
        line-height: 39px;
    }

    .home-categories ul {
        margin-left: auto;
        margin-right: auto;
        padding-left: 0px;
    }

    .home-categories ul li {
        padding-left: 20px;
        padding-right: 20px;
    }


    .webshop-categories-title + .container {
        margin-top: -25px;
    }

    .webshop-categories-container {
        padding-bottom: 40px;
    }
}

.webshop-categories-container > div > div {
    margin-bottom: 20px;
}

.webshop-categories-container img {
    height: 310px;
}












@media (max-width: 991px) {

    .webshop-categories-title + .container {
        margin-top: -25px;
    }

    .webshop-categories-container {
        padding-bottom: 40px;
    }
}

.webshop-categories-container > div > div {
    margin-bottom: 20px;
}

.webshop-categories-container img {
    height: 310px;
    width: 100%;
    object-fit: cover;
}

.home-categories-title + .container .col-lg-4 > div {
    height: 100%;
}


.home-categories-title + .container > .row {
    margin-left: -10px;
    margin-right: -10px;
}

.home-categories-title + .container > .row > div {
    padding-left: 10px;
    padding-right: 10px;
}

.home-categories-title + .container > .row > div > .row {
    margin-left: -10px;
    margin-right: -10px;
}

.home-categories-title + .container > .row > div > .row > div {
    padding-left: 10px;
    padding-right: 10px;
}

.home-categories .col-lg-8 .row + .row {
    margin-top: 20px !important;
}

.home-categories .col-lg-4 a {
    width: 100%;
    height: 100%;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-align-content: center;
    align-content: center;
    -webkit-align-items: center;
    align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
    flex-direction: column;
    -webkit-flex-direction: column;
    /*background: $darkgrey-v1;*/
    color: $darkgrey-v1;
    font-family: $font5;
    font-weight: normal;
    font-style: normal;
    font-size: 36px;
    line-height: 36.65px;
    text-transform: uppercase;
    background: transparent;
    transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
    min-height: 250px;
    /*padding-top: 20px;
    padding-bottom: 20px;*/
    box-sizing: border-box;
}

.home-categories .col-lg-4 a:hover,
.home-categories .col-lg-4 a:focus {
    text-decoration: none;
    background: $brown-v1;
}

.home-categories .col-lg-4 a .txt-overlay {
    display: none;
}

.home-categories .col-lg-4 a:hover .txt-overlay,
.home-categories .col-lg-4 a:focus .txt-overlay {
    display: flex;
    flex-direction: column;
    -webkit-flex-direction: column;
}

.home-categories .col-lg-4 a img {
    z-index: 0;
    object-position: 60% 50%;
}

.home-categories .col-lg-4 a:hover img,
.home-categories .col-lg-4 a:focus img {
    z-index: -1;
}

.home-categories .txt {
    max-width: 250px;
    text-align: center;
}

.home-categories .col-lg-4 .txt-overlay {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    text-align: center;
}

.home-categories .col-lg-4 .txt-overlay .shop-btn {
    font-family: $font1;
    font-size: 17px;
    color: $darkgrey-v1;
    border: 1px solid $darkgrey-v1;
    display: inline-block;
    padding: 0 24px;
    margin-top: 20px;
}

.home-categories .col-lg-4 .txt-overlay .shop-btn:hover,
.home-categories .col-lg-4 .txt-overlay .shop-btn:focus {
    background-color: $darkgrey-v1;
    color: $white;
}

.home-categories img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.home-categories .col-lg-8 .row + .row {margin-top: 30px;}

.home-ontspannen-genieten {
    /*background: url('/images/bg.jpg') no-repeat 50% 50%;*/
    background: url('/images/fabric-textured-background.jpg') no-repeat 50% 50%;
    background-size: cover;
    padding-top: 150px;
    padding-bottom: 100px;
}

.home-ontspannen-genieten-row1 {padding-bottom: 170px;}
.home-ontspannen-genieten-row2 {padding-top: 170px;}

.home-ontspannen-genieten-row1-col2 img {
    position: absolute;
    top: 33.33%;
    right: 0%;
    -webkit-transform: translate(0%, -50%);
    -moz-transform: translate(0%, -50%);
    -ms-transform: translate(0%, -50%);
    -o-transform: translate(0%, -50%);
    transform: translate(0%, -50%);
    z-index: 2;
    max-height: 674px;
    max-width: 577px;
    object-fit: cover;
}

.home-ontspannen-genieten-row2-col1 img {
    position: absolute;
    top: 67.666%;
    /*left: 0%;*/
    left: 15px;
    -webkit-transform: translate(0%, -50%);
    -moz-transform: translate(0%, -50%);
    -ms-transform: translate(0%, -50%);
    -o-transform: translate(0%, -50%);
    transform: translate(0%, -50%);
    z-index: 2;
    max-height: 577px;
    max-width: 465px;
    object-fit: cover;
}

.home-warmte-in-de-huiskamer {
    padding-top: 105px;
    padding-bottom: 140px;
}

.home-warmte-in-de-huiskamer-row1-col2 {
    text-align: right;
}

.home-warmte-in-de-huiskamer-row1 a {
    display: inline-block;
    background-color: $brown-v1;
    color: $white;
    font-family: $font1;
    font-size: 17px;
    padding: 0 28px;
    text-transform: uppercase;
    transition: all 0.25s ease;
    -webkit-transition: all 0.25s ease;
}

.home-warmte-in-de-huiskamer-row1 a:hover,
.home-warmte-in-de-huiskamer-row1 a:focus {
    background-color: $brown-v2;
    text-decoration: none;
}

.home-warmte-in-de-huiskamer-row1 img {
    max-height: 396px;
    max-width: 409px;
    object-fit: cover;
}

.home-warmte-in-de-huiskamer-row2 {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-align-content: center;
    align-content: center;
    -webkit-align-items: center;
    align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
}

.home-warmte-in-de-huiskamer-row2 img {
    max-height: 490px;
    max-width: 467px;
    object-fit: cover;
    object-position: 90% 50%;
}

.home-warmte-in-de-huiskamer p {
    color: $darkgrey-v2;
}

.home-warmte-in-de-huiskamer-row1-col1, .home-ontspannen-genieten-row1-col1 {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-align-content: center;
    align-content: center;
    -webkit-align-items: center;
    align-items: center;
    justify-content: flex-start;
    -webkit-justify-content: flex-start;
}

.home-warmte-in-de-huiskamer-row1-col1 > div, .home-ontspannen-genieten-row1-col1 > div{
    max-width: 460px;
    padding: 20px;
    box-sizing: content-box;
}


.home-warmte-in-de-huiskamer-row2-col2, .home-ontspannen-genieten-row2-col2 {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-align-content: center;
    align-content: center;
    -webkit-align-items: center;
    align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
}

.home-warmte-in-de-huiskamer-row2-col2 > div, .home-ontspannen-genieten-row2-col2 > div {
    max-width: 460px;
    padding: 20px;
    box-sizing: content-box;
}

.home-ontspannen-genieten-row2-col2 > div {
    max-width: 500px;
    padding: 20px;
    box-sizing: content-box;
}

.home-verpakken {
    /*background: url('/images/footer-bg.jpg') no-repeat 50% 50%;*/
    background: url('/images/fabric-textured-background.jpg') no-repeat 50% 50%;
    background-size: cover;
    padding-top: 130px;
    padding-bottom: 110px;
}


.home-verpakken-col1 > div {
    max-width: 480px;
    text-align: justify;
    padding: 20px;
    box-sizing: content-box;
}

.home-verpakken-col1 p {margin-bottom: 0px;}

.home-verpakken-col2 > div {
    position: relative;
    z-index: 1;
    height: 100%;
}

.home-verpakken-col2 img {
    position: absolute;
    top: calc(50% - 10px);
    right: 0%;
    -webkit-transform: translate(0%, -50%);
    -moz-transform: translate(0%, -50%);
    -ms-transform: translate(0%, -50%);
    -o-transform: translate(0%, -50%);
    transform: translate(0%, -50%);
    z-index: 2;
    max-height: 674px;
    max-width: 577px;
    object-fit: cover;
}

.home-merken h2 {
    background: -webkit-linear-gradient(#f0e9e3, #fff 75%);
    background: linear-gradient(#f0e9e3, #fff 75%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: $font2;
    font-size: 179.76px;
    line-height: 168.91px;
    letter-spacing: 20px;
    margin-left: -10px;
}

.home-merken {
    padding-top: 50px;
    padding-bottom: 100px;
}

.home-merken > div {
    padding-left: 0px;
}

.home-brands > .container {
    max-width: 880px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
}

.home-brands .row .row + .row {
    margin-top: 90px;
}

.home-brands a {
    pointer-events: none;
    cursor: default;
}

.no-products-added-to-cart .btn {
    display: inline-block;
    background-color: $brown-v1;
    color: #fff;
    font-family: "Montserrat-Light", sans-serif;
    font-size: 17px;
    padding: 14px 28px;
    text-transform: uppercase;
    transition: all 0.25s ease;
    -webkit-transition: all 0.25s ease;
}

.no-products-added-to-cart .btn:hover,
.no-products-added-to-cart .btn:focus {
    background-color: $brown-v2;
    text-decoration: none;
}



.category-detail .product-list-item {
    text-align: center;
    border: none;
}

.category-detail .product-list-item:hover {
    background-color: transparent;
}

.category-detail .product-list-item:before {
    display: none;
}

.category-detail .product-list-item .product-info:before {
    display: none;
}

.category-detail .product-title {
    font-family: $font1;
    color: $darkgrey-v1;
    font-size: 18px;
}

.category-detail .product-list-item .product-info .product-price {
    font-family: $font2;
    color: $darkgrey-v1;
    font-size: 20px;
}

.category-detail-main {
    padding-bottom: 40px;
}

.categories-filter-title h4 {
    font-family: $font5;
    font-weight: normal;
    font-style: normal;
    font-size: 18px;
    line-height: 36.65px;
    color: $brown-v1;
    /*border-bottom: 1px solid rgba(18, 14, 14, 0.2);*/
    text-transform: uppercase;
}



#product-detail {}

#product-detail > div > div {
    padding-top: 20px !important;
}

#product-detail .productgalleryslider {
    padding-left: 0px;
    padding-right: 10px;
}

#product-detail .productgallerymainimg {
    padding-left: 10px;
}

#product-detail .slick-slider .slick-track {
    margin-top: -5px;
}

#product-detail .main-image {
    width: 100%;
    height: 100%;
    /*max-height: 460px;*/
    max-height: 529px;
    text-align: center;
    border: 1px solid #C4C4C4;
    max-width: 529px;
    margin-left: 0px;
    margin-right: auto;
}

#product-detail .image-slide {
    max-width: 529px;
    margin-left: 0px;
    margin-right: auto;
}

#product-detail .main-image img {
    width: 100%;
    height: 100%;
    max-height: 460px;
    /*object-fit: cover;*/
    object-fit: contain;
}

#product-detail .product-title {
    font-family: $font2;
    font-size: 30px;
    color: $brown-v1;
    text-align: left;
    letter-spacing: -1px;
}

#product-detail .brand-name {
    font-family: $font2;
    color: $brown-v2;
    font-size: 25px;
}

#product-detail .product-detail-price {
    font-family: $font2;
    color: $darkgrey-v1;
    font-size: 20px;
}

#product-detail .description-top {
    font-family: $font1;
    color: $darkgrey-v1;
    font-size: 18px;
    line-height: 36px;
}

#product-detail .description-top p {
    margin-bottom: 0px;
}

#product-detail .description-top-link {
    font-family: $font1;
    color: $darkgrey-v1;
    font-size: 16px;
    line-height: 26px;
    border-bottom: 1px solid rgba(39,33,30,0.251);
    display: inline-block;
}

#product-detail .description-top-link:hover,
#product-detail .description-top-link:focus {
    border-bottom: 1px solid rgba(39,33,30,1);
    text-decoration: none;
}

#product-detail .variants .variant:hover .image-container,
#product-detail .variants .variant.active-variant .image-container {
    border: 2px solid rgba(39,33,30,0.251);
}

#product-detail .variants .variant {
    padding-left: 0.25rem !important;
    padding-right: 0.25rem !important;
    padding-top: 0.25rem !important;
}


#product-detail .variants .image-container img {
    width: 100%;
    height: 100%;
    max-width: 96px;
    max-height: 96px;
}

#product-detail .qty-group input {
    border: none !important;
}

#product-detail .qty-group {
    border: 1px solid #C4C4C4;
    box-sizing: border-box;
    height: 45px;
}

.qty-add-to-cart-max {
    max-width: 360px;
}

.qty-group .qty-up {
    position: absolute;
    bottom: 4px;
    right: 7px;
}

/*#product-detail .qty-group .qty-down {
    position: relative;
    bottom: 0px;
    left: 0px;
    font-family: $font1;
    color: $darkgrey-v1;
    font-size: 14px;
}

#product-detail .qty-group .qty-up {
    position: relative;
    bottom: 0px;
    right: 0px;
    font-family: $font1;
    color: $darkgrey-v1;
    font-size: 14px;
}*/

/*
#product-detail .qty-group .qty-field {
    border: 1px solid rgb(214, 214, 214) !important;
    width: 120px;
    height: 34px !important;
    border-radius: 0px !important;
    margin-left: 10px;
    margin-right: 10px;
}
*/

#product-detail .order-btn {
    /* background: $brown-v1;
    color: $white;
    font-family: $font1;
    font-size: 17px;
    text-transform: uppercase;
    border-radius: 0px;
    padding: 0 35px;*/
    font-family: 'Roboto';
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    color: #FFFFFF;
    padding: 0px 35px;
    display: inline-block;
    transition: all 0.25s ease;
    -webkit-transition: all 0.25s ease;
    height: 45px;
    line-height: 45px;
}

#product-detail .order-btn:hover,
#product-detail .order-btn:focus {
    background: $brown-v2;
}

#product-detail .qty-add-to-cart .col-5 {
    padding-right: 0px;
}

#product-detail .qty-add-to-cart p {
    margin-top: 0px;
    margin-bottom: 0px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 26px;
    color: #000;
}

#product-detail .productconfiguration-name {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 32px;
    color: #1E1E20;
}

#product-detail .order-btn img {
    margin-right: 10px;
}

.qty-add-to-cart .qty-group {

}

#product-detail .variants {}

#product-detail .product-usps {

}

#product-detail .product-usps ul {
    list-style: none;
    padding-left: 0px;
}

#product-detail .product-usps li svg {
    margin-right: 15px;
    font-weight: bold;
}

#properties-description {
    /*background: url('/images/bg.jpg') no-repeat 50% 50%;*/
    background: url('/images/fabric-textured-background.jpg') no-repeat 50% 50%;
    background-size: cover;
    padding-top: 90px;
    padding-bottom: 80px;
}

#properties-description .properties h2 {
    font-family: $font2;
    font-size: 19.98px;
    line-height: 39.96px;
    color: $brown-v1;
    text-transform: none;
    margin-top: 0px !important;
    margin-bottom: 0px !important;
}

#properties-description .properties h2 + p {
    margin-top: 0px !important;
}

#properties-description .description h2 {
    font-family: $font5;
    font-weight: normal;
    font-style: normal;
    font-size: 38px;
    line-height: 39.96px;
}

#properties-description .description span + h2 {
    margin-top: 5px !important;
}

.product-list-item .image-container {
    padding-bottom: inherit;
}

.product-list-item .image-container img {
    /*max-width: 240px;
    max-height: 240px;*/
    width: 100%;
    height: 100%;
    object-fit: contain;
    position: relative;
    height: 300px;
}


#related-products h2 {
    max-width: 480px;
    font-weight: 400;
}

section#related-products {
    background: $white;
    padding-bottom: 0px !important;
}

#related-products .related-products {
    padding-top: 0px !important;
    padding-bottom: 10px !important;
}

#related-products .product-list-item {
    border: 1px solid $white;
}

#related-products .product-list-item .product-info:before {
    background-color: $white;
}

#related-products .product-list-item .image-container {
    padding-bottom: inherit;
}

#related-products .product-list-item .image-container img {
    max-width: 240px;
    max-height: 240px;
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: relative;
}

#related-products .product-list-item:before {
    display: none !important;
}

#related-products .product-list-item .product-info {
    text-align: center;
}

.category-detail-main .product-info {
    text-align: center;
}

.category-detail-main .product-info  .product-price {
    font-family: $font2;
    font-size: 20px;
    color: $darkgrey-v1;
}

#related-products .product-list-item .product-info .product-title {
    font-family: $font1;
    color: $darkgrey-v1;
    max-width: 200px;
    margin-left: auto;
    margin-right: auto;
}


.back-to-webshop {
    padding-top: 20px;
    /*margin-top: 0px !important;*/
    margin-top: 3rem !important;
    padding-bottom: 20px !important;
}

.back-to-webshop a {
    background: $brown-v1;
    color: #fff;
    font-family: $font1;
    font-size: 17px;
    text-transform: uppercase;
    border-radius: 0px;
    padding: 0 43px;
    display: inline-block;
    transition: all 0.25s ease;
    -webkit-transition: all 0.25s ease;
}

.back-to-webshop a:hover,
.back-to-webshop a:focus {
    background: $brown-v2;
    text-decoration: none;
}


.privacyverklaring-main-content-table-desktop {
    overflow-x: auto;
}

.privacyverklaring-main-content-table-desktop table {
    border-collapse: collapse;
}

.privacyverklaring-main-content-table-desktop table tr {
    border-bottom: 1px solid #ccc;
}

.privacyverklaring-main-content-table-desktop table tr th,
.privacyverklaring-main-content-table-desktop table tr td {
    text-align: left;
    padding: 5px 15px 5px 5px;
}

.pager {
    margin-bottom: 40px;
}

.pager a {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    font-size: 20px;
    line-height: 24px;
    /* identical to box height */
    text-align: center;
    color: #2B2B2B;
}

.pager .my-active span {
    font-family: $font2;
    color: $brown-v1;
    font-size: 20px;
    font-weight: bold;
}

.pager .prev-btn a, .pager .next-btn a {
    background: #74C6C7;
    color: #FFF;
    padding: 8px 15px;
    box-sizing: border-box;
}

.pager .prev-btn a:hover, .pager .next-btn a:hover,
.pager .prev-btn a:focus, .pager .next-btn a:focus {
    background: #2B2B2B;
}

.pager .disabled {
    display: none;
}

/*.category-detail-header,*/ .cartnav {
                                 /*background: url('/images/bg.jpg') no-repeat 50% 50%;*/
                                 background: url('/images/fabric-textured-background.jpg') no-repeat 50% 50%;
                                 background-size: cover;
                                 padding-top: 40px;
                                 padding-bottom: 40px;
                             }

.checkout-title-container {
    position: relative;
    z-index: 0;
    background: #74C6C7;
}

.checkout-top-usps {
    background: #2B2B2B;
}




.category-detail-header h1, .product-detail-header h1, .checkout-title-container h1 {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: bold;
    font-size: 48px;
    line-height: 52px;
    color: #FFFFFF;
    text-transform: none;
}

.product-detail-header h1 {
    display: block;
}

.category-detail-header a,
.category-detail-header a + svg,
.category-detail-header i,
.product-detail-header a,
.product-detail-header a + svg,
.product-detail-header i {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 30px;
    color: #FFFFFF;
}



.category-detail-header > div:nth-child(1) {
    position: relative;
    z-index: 0;
    background: #74C6C7;
}

.category-detail-header > div:nth-child(1)::before,
.product-detail-header > div:nth-child(1) {
    position: relative;
    z-index: 0;
}

.product-detail-header > div:nth-child(1)::before {
    content: "";
    background: url('/images/Frame7.png') no-repeat;
    position: absolute;
    top: calc(50% + 20px);
    left: 100%;
    -webkit-transform: translate(-100%, -50%);
    -moz-transform: translate(-100%, -50%);
    -ms-transform: translate(-100%, -50%);
    -o-transform: translate(-100%, -50%);
    transform: translate(-100%, -50%);
    z-index: -1;
    width: 100%;
    height: 100%;
    max-width: 610px;
    max-height: 128px;
}

.category-detail-header > div:nth-child(1) > div:nth-child(1),
.product-detail-header > div:nth-child(1) > div:nth-child(1){
    padding-top: 40px;
    padding-bottom: 20px;
}

.category-detail-header .categorie-top-usps, .product-top-usps {
    background: #2B2B2B;
}

.category-detail-header .categorie-top-usps * {

}

.category-detail-main .catprod .image-container {
    border: 1px solid #C4C4C4;
    box-sizing: border-box;
}

.category-detail-main .catprod .product-info {
    background: #2B2B2B;
    margin-left: 0px;
    margin-right: 0px;
    padding: 15px 15px !important;
    height: 113px;
}

.category-detail-main .catprod .product-info .product-title {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 29px;
    color: #FFFFFF;
    text-align: left;
}

.category-detail-main .catprod .product-info .product-price {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 29px;
    color: #FFFFFF;
    text-align: left;
}

.category-detail-main .catprod .product-info .col-10 {
    padding-left: 0px !important;
}

.category-detail-main .catprod .product-info .col-2 img {
    min-width: 30px;
}

/*.category-detail-main .catprod .product-info .col-2 img {
    width: 30px;
    height: 30px;
    transform: scale(1.4);
    -webkit-transform: scale(1.4);
}*/

.cartnav ul {
    margin-bottom: 0px;
}

.product-detail-header {
    /*background: url('/images/bg.jpg') no-repeat 50% 50%;*/
    /*background: url('/images/fabric-textured-background.jpg') no-repeat 50% 50%;*/
    background-size: cover;
    padding-top: 0px;
    padding-bottom: 0px;
    background: #74C6C7;
}

.category-detail-main .category-filter-item + .category-filter-item {
    position: relative;
    z-index: 0;
}

.category-detail-main .category-filter-item + .category-filter-item::before {
    content: '';
    display: block;
    position: absolute;
    left: 15px;
    z-index: -1;
    width: calc(100% - 30px);
    border-top: 1px solid rgba(116, 198, 199, 0.5);
}

.category-detail-main .category-filters-container {
    /*background: #f6f0ea;*/
    padding: 60px 50px 60px 0px;
    box-sizing: border-box;
}

.category-detail-main .category-filters-container h4 {
    /*border-bottom: 1px solid rgba(18, 14, 14, 0.2);*/
    padding-bottom: 10px;
}

.category-detail-main .category-filters-container h4,
.category-detail-main .category-filters-container h4 * {
    /*font-family: $font5;
    font-weight: normal;
    font-style: normal;
    color: $brown-v1;
    font-size: 18px;
    text-transform: uppercase;*/
    font-family: 'Roboto';
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 200%;
    color: #1E1E20;
    text-transform: none;
}

.category-detail-main .category-filters-container h4 > div > div {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-align-content: center;
    align-content: center;
    -webkit-align-items: center;
    align-items: center;
    justify-content: space-between;
    -webkit-justify-content: space-between;
}

.category-detail-main .category-filters-container h4 .fal {
    font-family: "Font Awesome 5 Pro";
    font-weight: 300;
    color: #272727;
    font-size: 18px;
}

.filter-mob .fal {
    font-size: 18px;
}

.filter-mob h4 {
    font-family: "Montserrat-Light", sans-serif;
    color: #a26c35;
    font-size: 18px;
    text-transform: uppercase;
}

.filter-mob h4 .fal {
    color: #272727;
}

.filter-mob h4 div {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-align-content: center;
    align-content: center;
    -webkit-align-items: center;
    align-items: center;
    justify-content: space-between;
    -webkit-justify-content: space-between;
}

.category-detail-header a, .product-detail-header a {
    font-family: $font1;
    /*color: $brown-v2;*/
    color: #FFF;
}

.category-detail-header a:hover,
.category-detail-header a:focus,
.product-detail-header a:hover,
.product-detail-header a:focus {
    color: $brown-v1;
    text-decoration: none;
}

.product-detail-header .align-items-center > div {
    position: relative;
    z-index: 10;
}

.category-detail-main > div > div.col-xl-9 {
    padding-top: 60px;
}

.category-detail-main .category-filters-container .category-filter-item > div {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-align-content: center;
    align-content: center;
    -webkit-align-items: center;
    align-items: center;
    justify-content: space-between;
    -webkit-justify-content: space-between;
    padding-top: 10px;
    padding-bottom: 10px;
}

.category-detail-main .category-filters-container .category-filter-item a {
    font-family: $font1;
    color: $darkgrey-v1;
    font-size: 18px;
}

.category-detail-main .category-filters-container .category-filter-item ul {
    list-style: none;
    padding-left: 0px;
}

/*.category-detail-main .category-filters-container .category-filter-item .sub-cat::marker {
        display: none;
    }*/

.category-detail-main .category-filters-container .category-filter-item .sub-cat a {
    display: inline-block;
}


#description-long-link {
    display: block;
    position: relative;
    top: -90px;
    visibility: hidden;
}

#brand-filter {
    color: $darkgrey-v1;
    font-family: $font1;
    font-size: 18px;
}

.header-home-icons {
    position: relative;
    z-index: 999;
}


.catprod {
    position: relative;
}

.catprod .lebel {
    position: absolute;
    top: 1px;
    /*left: 12px;*/
    left: 1px;
    background-color: #74C6C7;
    color: #FFF;
    box-sizing: border-box;
    padding: 5px 10px;
    font-family: $font2;
}


.over-ons-header > div > div > div,
.contact-header > div > div > div,
.algemene-voorwaarden-header > div > div > div,
.disclaimer-header > div > div > div,
.privacy-policy-header > div > div > div {
    position: relative;
    z-index: 0;
}

/*.over-ons-header > div > div > div > div:nth-child(1),
.contact-header > div > div > div > div:nth-child(1),
.algemene-voorwaarden-header > div > div > div > div:nth-child(1),
.disclaimer-header > div > div > div > div:nth-child(1),
.privacy-policy-header > div > div > div > div:nth-child(1) {
    z-index: 1;
    position: absolute;
    top: 50%;
    left: 100px;
    -webkit-transform: translate(0%, -50%);
    -moz-transform: translate(0%, -50%);
    -ms-transform: translate(0%, -50%);
    -o-transform: translate(0%, -50%);
    transform: translate(0%, -50%);
}*/

.over-ons-header h1,
.contact-header h1,
.algemene-voorwaarden-header h1,
.disclaimer-header h1,
.privacy-policy-header h1 {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: bold;
    font-size: 48px;
    line-height: 52px;
    color: #FFFFFF;
    max-width: 800px;
    text-transform: none;
}

.over-ons-header > div,
.contact-header > div,
.algemene-voorwaarden-header > div,
.disclaimer-header > div,
.privacy-policy-header > div {
    padding-top: 40px;
    padding-bottom: 20px;
}

.over-ons-header,
.contact-header,
.algemene-voorwaarden-header,
.disclaimer-header,
.privacy-policy-header {
    position: relative;
    z-index: 0;
}

.over-ons-header::before,
.contact-header::before,
.algemene-voorwaarden-header::before,
.disclaimer-header::before,
.privacy-policy-header::before {
    content: '';
    background: url('/images/Frame7.png') no-repeat;
    position: absolute;
    top: 50%;
    left: 100%;
    -webkit-transform: translate(-100%, -50%);
    -moz-transform: translate(-100%, -50%);
    -ms-transform: translate(-100%, -50%);
    -o-transform: translate(-100%, -50%);
    transform: translate(-100%, -50%);
    z-index:-1;
    width: 100%;
    height: 100%;
    max-width: 610px;
    max-height: 128px;
}

.over-ons-usps {
    background: #2B2B2B;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 24px;
    color: #FFFFFF;
}


.over-ons-rust {
    /*background: url('/images/bg.jpg') no-repeat 50% 50%;*/
    background: url('/images/fabric-textured-background.jpg') no-repeat 50% 50%;
    background-size: cover;
    padding-top: 180px;
    padding-bottom: 70px;
}

.over-ons-rust h2 {
    max-width: 320px;
}

.over-ons-rust p {
    max-width: 460px;
}

/*
.over-ons-rust-col2 {
    position: relative;
}

.over-ons-rust-col2 img {
    position: absolute;
    top: 66%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    max-height: 662px;
    max-width: 557px;
    object-fit: cover;
}*/

.over-ons-uniek-verdeler > div > div {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-align-content: center;
    align-content: center;
    -webkit-align-items: center;
    align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
}

.over-ons-kwaliteit {
    padding-top: 200px;
    padding-bottom: 140px;
}

.over-ons-kwaliteit > div > div {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-align-content: center;
    align-content: center;
    -webkit-align-items: center;
    align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
}

.over-ons-kwaliteit > div > div > div:nth-child(2) {

    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-align-content: center;
    align-content: center;
    -webkit-align-items: center;
    align-items: center;
    justify-content: center;
    -webkit-justify-content: center;

}

.over-ons-kwaliteit img {
    max-height: 490px;
    max-width: 467px;
    object-fit: cover;
}

.over-ons-kwaliteit h2 {
    max-width: 340px;
}

.over-ons-kwaliteit p {
    color: $darkgrey-v2;
    max-width: 470px;
}

.over-ons-uniek-verdeler > div {
    padding-top: 90px;
    padding-bottom: 90px;
}

.over-ons-slider-section {
    background: url('/images/about-us-slider-bg.jpg') no-repeat 50% 50%;
    background-size: cover;
    padding-top: 90px;
    padding-bottom: 90px;
}

.over-ons-slider-section .uk-slidenav {
    color: $brown-v1;
}

.over-ons-slider-section .uk-slidenav:hover,
.over-ons-slider-section .uk-slidenav:focus {
    color: $brown-v2;
}

.over-ons-slider-section .uk-slider-items img {
    max-height: 280px;
    width: 100%;
    object-fit: cover;
}

.over-ons-contact {
    /*background: url('/images/bg.jpg') no-repeat 50% 50%;*/
    background: url('/images/fabric-textured-background.jpg') no-repeat 50% 50%;
    background-size: cover;
    padding-top: 150px;
    padding-bottom: 140px
}

.over-ons-contact p {
    max-width: 420px;
}

.over-ons-contact a {
    display: inline-block;
    background-color: #a26c35;
    color: #fff;
    font-family: "Montserrat-Light", sans-serif;
    font-size: 17px;
    padding: 7px 15px;
    text-transform: uppercase;
    transition: all 0.25s ease;
    -webkit-transition: all 0.25s ease;
    border-radius: 0px;
}

.over-ons-contact a:hover,
.over-ons-contact a:focus {
    background-color: #ccb59e;
    text-decoration: none;
}

.over-ons-contact-col2 {
    position: relative;
    z-index: 1;
}

.over-ons-contact-col2 img {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    max-height: 674px;
    max-width: 577px;
    object-fit: cover;
}

.over-ons-contact > div > div > div:nth-child(1) {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-align-content: center;
    align-content: center;
    -webkit-align-items: center;
    align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
}

.contact-main form input:not([type=checkbox]):not([type=radio]) {
    height: 40px !important;
}

.contact-main form input:not([type=checkbox]):not([type=radio]),
.contact-main form textarea {
    font-size: 18px !important;
}

.contact-main .privacy-notice-paragraph {
    font-size: 12px;
    line-height: 18px;
}

.contact-main .privacy-notice-paragraph a {
    color: $brown-v2;
}

.contact-main .privacy-notice-paragraph a:hover,
.contact-main .privacy-notice-paragraph a:focus {
    color: $brown-v1;
    text-decoration: none;
}

.contact-main input[type=checkbox] {
    border: 1px solid $brown-v1;
}

.contact-main textarea {
    /*border: 1px solid $brown-v1 !important;*/
}

.contact-main label::before {
    display: none !important;
}

.contact-main button {
    display: inline-block;
    background-color: $brown-v1;
    border: 1px solid $brown-v1;
    color: #fff;
    font-family: "Montserrat-Light", sans-serif;
    font-size: 17px;
    padding: 0 28px;
    text-transform: uppercase;
    transition: all 0.25s ease;
    -webkit-transition: all 0.25s ease;
    margin-top: 20px;
}

.contact-main button:hover,
.contact-main button:focus {
    background-color: $brown-v2;
    border: 1px solid $brown-v2;
    text-decoration: none;
}

.contact-main > div > div > div:nth-child(2) a {
    color: $main-color;
}

.contact-main > div > div > div:nth-child(2) a:hover,
.contact-main > div > div > div:nth-child(2) a:focus {
    color: $black;
}

.contactmap {
    margin-top: 40px;
}

.contact-main #form-group-checkbox-group-1635514227957 label {
    font-size: 18px;
    line-height: 24px;
}

.site-footer {
    /*background: url('/images/footer-bg.jpg') no-repeat 50% 50%;*/
    /*background: url('/images/fabric-textured-background.jpg') no-repeat 50% 50%;
    background-size: cover;*/
    background: $black;
    padding-top: 70px;
    padding-bottom: 70px;
}

.site-footer * , .site-footer a {
    font-family: $font1;
    font-style: normal;
    font-weight: 300;
    font-size: 20px;
    line-height: 30px;
    color: $white;
}

.site-footer a:hover, .site-footer a:focus {
    color: rgba(255, 255, 255, 0.8);
    text-decoration: none;
}

.site-footer h3 {
    font-family: $font1;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 30px;
    margin-bottom: 0px;
    text-transform: none;
}
/*
.footer-col2 {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-align-content: center;
    align-content: center;
    -webkit-align-items: center;
    align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
}

.footer-col3 {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-align-content: center;
    align-content: center;
    -webkit-align-items: center;
    align-items: center;
    justify-content: flex-end;
    -webkit-justify-content: flex-end;
}*/

.footer-col4 {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-align-content: center;
    align-content: center;
    -webkit-align-items: center;
    align-items: center;
    justify-content: flex-end;
    -webkit-justify-content: flex-end;
    text-align: right;
}

.searchpage-content > div {
    padding-top: 70px;
    padding-bottom: 70px;
}

.searchpage-content .search-field {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-align-content: center;
    align-content: center;
    -webkit-align-items: center;
    align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
}

.searchpage-content .search-field-input,
.searchpage-content .do-search-button {
    display: inline-block;
}

.searchpage-content .search-field-input input {min-width: 300px;padding-left: 10px;padding-right: 10px;}

.searchpage-content .do-search-button {
    background: $brown-v1;
    color: $white;
    padding: 10px 20px;
    box-sizing: border-box;
    height: 48px;
    line-height: 36px;
    cursor: pointer;
}

.searchpage-content .do-search-button:hover,
.searchpage-content .do-search-button:focus {
    background: $brown-v2;
}


.uk-offcanvas-bar {
    /*background: url(/images/bg.jpg) no-repeat 50% 50%;*/
    /*background: url('/images/fabric-textured-background.jpg') no-repeat 50% 50%;*/
    background: #FFF;
    background-size: cover;
}

.uk-offcanvas-bar .menu-main-menu li {
    display: block;
    width: 100%;
}

.offcanvas-logo {
    margin-top: 100px;
    margin-bottom: 40px;
    display: inline-block;
}

.uk-offcanvas-bar .menu-main-menu {
    height: auto !important;
}

.uk-offcanvas-bar .menu-main-menu li a {
    font-family: "Montserrat-Bold", sans-serif;
    font-size: 22px;
    line-height: 38px;
    color: #a26c35 !important;
    text-transform: uppercase;
}

.uk-offcanvas-bar .menu-main-menu li a:hover,
.uk-offcanvas-bar .menu-main-menu li a:focus {
    color: $brown-v2 !important;
    text-decoration: none;
}

.uk-offcanvas-close {
    /*color: #FFF;*/
    color: $brown-v2;
}

.uk-offcanvas-close:hover,
.uk-offcanvas-close:focus {
    color: $brown-v1;
}

.uk-offcanvas-close svg {
    width: 26px;
    height: 26px;
}


ul.menu-main-menu > li > a:after, ul.menu-main-menu > li > a:visited:after, ul.menu-main-menu > li > span:after, ul.menu-language-items > li > a:after, ul.menu-language-items > li > a:visited:after, ul.menu-language-items > li > span:after {
    display: none;
}

.form-steps li {
    border: none;
    transform: none;
}


.form-steps {
    padding-left: 0px;
}


.form-steps li.enabled, .form-steps li.current {
    border: none;
    background: none;
}

.form-steps li svg.fa-check-circle, .form-steps li svg.fa-circle,
.form-steps li i.fa-check-circle, .form-steps li i.fa-circle {
    margin-right: 8px;
    position: relative;
    top: 5px;
    width: 27px !important;
    height: 27px;
    color: $brown-v2;
    font-size: 27px;
}

.form-steps svg.fa-horizontal-rule, .form-steps i.fa-horizontal-rule {
    max-width: 14px;
    margin-left: 6px;
    /*margin-right: 6px;*/
    margin-right: 15px;
}

.form-steps li a, .form-steps li span {
    transform: none;
    padding: 10px 0px;
    color: #000;
}

.cart-content-section {
    padding-top: 70px;
    padding-bottom: 70px;
}


.order-btn, .proceed-button a {
    background-color: $brown-v1;
}

.order-btn:hover, .proceed-button a:hover,
.order-btn:focus, .proceed-button a:focus {
    /*background-color: $brown-v2;*/
    background-color: #2B2B2B;

}

.checkout-page {
    .invalid-feedback {
        display:block !important;
        list-style: none;
        padding-left: 0;
    }
}

.checkout-page .butn-next {
    display: inline-block;
    background-color: $brown-v1;
    color: #fff;
    font-family: $font1;
    font-size: 20px;
    padding: 10px 28px;
    /*text-transform: uppercase;*/
    transition: all 0.25s ease;
    -webkit-transition: all 0.25s ease;
    height: 45px;
    line-height: 25px;
}

.checkout-page .butn-next:hover,
.checkout-page .butn-next:focus {
    background-color: #2B2B2B;
    text-decoration: none;
}


.checkout-page .butn-light {
    display: inline-block;
    background-color: #F3F3F3;
    /*color: #fff;
    font-family: $font1;
    font-size: 17px;
    padding: 10px 28px;*/
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    font-size: 20px;
    /*line-height: 24px;*/
    /* identical to box height */
    color: #0A0A0A;
    padding: 10px 28px;
    /*text-transform: uppercase;*/
    transition: all 0.25s ease;
    -webkit-transition: all 0.25s ease;
    height: 45px;
    line-height: 25px;
}

.checkout-page .butn-light:hover,
.checkout-page .butn-light:focus {
    background-color: $brown-v1;
    text-decoration: none;
}

.login-existing-customer a {
    color: $brown-v1;
}

.login-existing-customer a:hover,
.login-existing-customer a:focus {
    color: $brown-v2;
    text-decoration: none;
}

#login-form-modal .btn {
    display: inline-block;
    background-color: $brown-v1;
    color: #fff;
    font-family: $font1;
    font-size: 17px;
    padding: 10px 28px;
    text-transform: uppercase;
    transition: all 0.25s ease;
    -webkit-transition: all 0.25s ease;
}

#login-form-modal .btn:hover,
#login-form-modal .btn:focus {
    background-color: $brown-v2;
}

.form-control, .select2-container .selection .select2-selection {
    border-radius: 0px;
}

.select2-container .selection .select2-selection {
    border-radius: 0px !important;
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
    border-radius: 0px !important;
}

.cart-item-block {
    border: 1px solid #ccc;
    padding: 10px;
    /*border-radius: 6px;*/
    border-radius: 0px;
}

.cart-item-block .image-container img {
    /*border-radius: 6px;*/
    border-radius: 0px;
}

.cart-content-row + .cart-content-row {
    border-top: none;
    margin-top: 30px;
}

.filter-mob {
    padding-left: 15px;
    padding-right: 15px;
}

.filter-mob a {
    font-family: "Montserrat-Light", sans-serif;
    color: #27211e;
    font-size: 18px;
}

.filter-mob .category-filter-item > div {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-align-content: center;
    align-content: center;
    -webkit-align-items: center;
    align-items: center;
    justify-content: space-between;
    -webkit-justify-content: space-between;
}

.filter-mob .subcategories-list {
    list-style: none;
    padding-left: 0px;
}

.filter-mob .subcategories-list li a {
    display: inline-block;
}

.mini-cart .badge-main {
    /*background-color: $brown-v1;*/
    background-color: #2b2b2b;
    color: $white;
    position: absolute;
    right: -2px;
    top: -14px;
}


.size-box input + label {
    padding: 5px 10px;
}

.size-box input:checked + label {
    background-color: $brown-v1;
    color: $white;
}

.form-control:focus, .select2-container .selection .select2-selection:focus {
    box-shadow: none;
}

.select2-container .selection .select2-selection {
    /*border: 1px solid #a26c35;*/
    border: 1px solid #c4c4c4;
    border-radius: 0.25rem;
    height: 45px;
}

.voorwaarden-link {color: $brown-v1;}
.voorwaarden-link:hover,
.voorwaarden-link:focus {color: $brown-v2;}

.form-control:focus, .select2-container .selection .select2-selection:focus {
    border: 1px solid #a26c35;
    /*border-color: #a26c35;*/
    border: 1px solid #C4C4C4;
    border-color: #C4C4C4;
}

.home-brands .row {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-align-content: center;
    align-content: center;
    -webkit-align-items: center;
    align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
}

.home-brands .row img {
    max-height: 120px;
    object-fit: contain;
}


.algemene-voorwaarden-main a {
    color: #ccb59e;
}

.algemene-voorwaarden-main a:hover,
.algemene-voorwaarden-main a:focus {
    color: #a26c35;
    text-decoration: none;
}

.algemene-voorwaarden-main p,
.algemene-voorwaarden-main li {
    color: #27201c;
}

.algemene-voorwaarden-main h3 {
    font-family: $font5;
    font-weight: normal;
    font-style: normal;
    color: $brown-v1;
    font-size: 20px;
    text-transform: uppercase;
    letter-spacing: 2px;
    text-align: left;
}

.algemene-voorwaarden-main #art1,
.algemene-voorwaarden-main #art2,
.algemene-voorwaarden-main #art3,
.algemene-voorwaarden-main #art4,
.algemene-voorwaarden-main #art5,
.algemene-voorwaarden-main #art6,
.algemene-voorwaarden-main #art7,
.algemene-voorwaarden-main #art8,
.algemene-voorwaarden-main #art9,
.algemene-voorwaarden-main #art10,
.algemene-voorwaarden-main #art11,
.algemene-voorwaarden-main #art12,
.algemene-voorwaarden-main #art13,
.algemene-voorwaarden-main #art14,
.algemene-voorwaarden-main #art15,
.algemene-voorwaarden-main #art16 {
    display: block;
    position: relative;
    top: -160px;
    visibility: hidden;
}


.product_option_remarks {
    display: none;
}

.menuwebshop {margin-left: 30px;}

/*.webshop-categories > div.container {margin-bottom: 20px;}
.webshop-categories > div.container {
    padding-left: 0px;
    padding-right: 0px;
}
.webshop-categories > div.container > div.row {
    margin-left: -10px;
    margin-right: -10px;
}

.webshop-categories > div.container > div.row > div {
    padding-left: 10px;
    padding-right: 10px;
}

.webshop-categories > div.container > div.row > div > div.row {
    margin-left: -10px;
    margin-right: -10px;
}*/



/*@media (min-width: 960px) and (max-width: 1199px) {
        .over-ons-slider-section .uk-slidenav {
            background: #FFF;
            color: #a26c35;
        }

        .home-product-slider-nav {
            display: block !important;
        }
    }*/

input, select, textarea {-webkit-appearance: none;}

.price-input:before {
    top: 2px;
}

.cart-page .qty-selector .cart-qty {
    height: 35px;
    border: none;
    width: auto;
    text-align: left !important;
}

.toggle-filter {
    border-radius: 0px !important;
}

.cart-qty-selector {
    position: relative;
    display: inline-block;
    border: 1px solid $brown-v1;
    padding: 0px 5px;
    box-sizing: border-box;
}

.qty-selector .cart-qty-select-open {
    height: auto;
    width: auto;
    background-color: transparent;
    border: none;
    color: #fff;
    text-align: center;
    margin-left: 0px;
    padding: 0px 0;
    cursor: pointer;
}

.qty-selector .cart-qty-select-options {
    left: -1px;
}


.cart-page .add-discount-code,
.checkout-page .add-discount-code {
    display: inline-block;
    background-color: #74C6C7;
    color: #fff;
    font-family: "Montserrat-Light", sans-serif;
    font-size: 17px;
    padding: 10px 28px;
    text-transform: uppercase;
    transition: all 0.25s ease;
    -webkit-transition: all 0.25s ease;
}

.cart-page .add-discount-code:hover,
.cart-page .add-discount-code:focus,
.checkout-page .add-discount-code:hover,
.checkout-page .add-discount-code:focus {
    background-color: #2B2B2B;
    text-decoration: none;
}

@media (min-width: 960px) and (max-width: 1680px) {
    .uk-position-center-left-out {right:calc(100% - 60px);}
    .uk-position-center-right-out {left:calc(100% - 60px);}
}

@media (min-width: 992px) and (max-width: 1199px) {

    .home-usps ul {
        padding-left: 12px;
        padding-right: 12px;
    }

    .home-usps ul li {
        padding-right: 12px;
    }

    .home-ontspannen-genieten-row1-col2 img {max-width: 457px;}
    .home-ontspannen-genieten-row2-col1 img {max-width: 457px;}
    /* .home-verpakken-col2 img {max-width: 457px;}*/
    .over-ons-rust-col2 img {max-width: 457px;}
    .over-ons-contact-col2 img {max-width: 457px;}
    .over-ons-slider-section {
        padding-bottom: 150px;
    }

    .home-categories-section {padding-bottom: 100px !important;}

    .home-ontspannen-genieten-row1 {
        padding-bottom: 100px;
    }
    .home-ontspannen-genieten-row2 {
        padding-top: 100px;
    }

}

@media (min-width: 768px) and (max-width: 991px) {
    .home-ontspannen-genieten-row1-col2 img {max-width: 357px;}
    .home-ontspannen-genieten-row2-col1 img {max-width: 357px;}
    /*.home-verpakken-col2 img {max-width: 357px;}*/
    .over-ons-rust-col2 img {max-width: 357px;}
    .over-ons-kwaliteit img {max-width: 367px;}
    .over-ons-contact-col2 img {max-width: 357px;}
    .over-ons-slider-section {
        padding-bottom: 140px !important;
    }
    .home-categories-section {padding-bottom: 100px !important;}
}

@media (min-width: 768px) {

    .home-main > div > div > div:nth-child(1) {
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-wrap: wrap;
        flex-wrap: wrap;
        -webkit-align-content: center;
        align-content: center;
        -webkit-align-items: center;
        align-items: center;
        justify-content: flex-start;
        -webkit-justify-content: flex-start;
    }

    .home-bottom > div > div > div:nth-child(2) {
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-wrap: wrap;
        flex-wrap: wrap;
        -webkit-align-content: center;
        align-content: center;
        -webkit-align-items: center;
        align-items: center;
        justify-content: flex-end;
        -webkit-justify-content: flex-end;
    }

    .home-bottom > div > div > div:nth-child(2) img {
        max-width: 552px;
        object-fit: contain;
    }




    .collapse.dont-collapse-sm {
        display: block;
        height: auto !important;
        visibility: visible;
    }

    .contact-main > div > div > div:nth-child(1) {padding-right: 20px;}
    .contact-main > div > div > div:nth-child(2) {padding-left: 20px;}

    /*.productgalleryslider {
        flex: 0 0 17.15%;
        max-width: 17.15%;
    }
    .productgallerymainimg {
        flex: 0 0 82.85%;
        max-width: 82.85%;
    }*/

    #product-detail > div > div > div:nth-child(1) {padding-right: 20px;}

    #product-detail > div > div > div:nth-child(2) {padding-left: 20px;}

    .catprod {
        padding-left: 12px !important;
        padding-right: 12px !important;
    }

}


@media (min-width: 992px) {

    .home-warmte-in-de-huiskamer-row2 {
        padding-top: 80px;
    }

    .cart-page .cart-page-cart-content-totals {
        margin-left: 4%;
    }

    .checkout-page .cart-totals-html-desktop {
        margin-left: 4%;
    }

    .cart-totals-html-mobile {
        display: none;
    }

    .searchpage-content {
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-wrap: wrap;
        flex-wrap: wrap;
        -webkit-align-content: center;
        align-content: center;
        -webkit-align-items: center;
        align-items: center;
        justify-content: center;
        -webkit-justify-content: center;
        min-height: 750px;
    }

    .contact-main > div > div > div:nth-child(1) {padding-right: 40px;}
    .contact-main > div > div > div:nth-child(2) {padding-left: 40px;}

    .privacyverklaring-main-content-table-mobile {
        display: none;
    }
}



@media (min-width: 1200px) {

    .over-ons-uniek-verdeler .over-ons-rust-col1 * {max-width: 615px;}
    .over-ons-uniek-verdeler .over-ons-rust-col2 {text-align: right;}
    .over-ons-uniek-verdeler .over-ons-rust-col2 img {max-width: 555px;}

    .site-footer .col-lg-4 {
        flex: 0 0 30.3333333333%;
        max-width: 30.3333333333%;
    }

    .site-footer .col-lg-2 {
        flex: 0 0 19.6666666667%;
        max-width: 19.6666666667%;
    }

   /* .container {
        max-width: 1470px;
    }*/

    .container {
        max-width: 1280px;
    }

    .category-detail-main, .checkout-page-form {
        max-width: 1280px;
    }



    .pager {margin-top: 60px;}

    .info-header > div {
        padding-left: 30px !important;
        padding-right: 30px !important;
    }

    /*.category-detail-header > div {
        padding-left: 262px;
    }*/

    .category-detail-header > div:nth-child(1) > div:nth-child(1), .category-detail-header > div:nth-child(1) > div:nth-child(1) {
        /*padding-left: 262px;*/
        max-width: 1280px;
        margin-left: auto;
        margin-right: auto;
    }

    .cartnav  > div {
        /*padding-left: 262px;*/
        max-width: 1280px;
        margin-left: auto;
        margin-right: auto;
    }

    .checkout-title-container > div {
        max-width: 1280px;
        padding-left: 0px;
        margin-left: auto;
        margin-right: auto;
    }

    .product-detail-header > div > div {
        /*padding-left: 262px;*/
        max-width: 1280px;
        margin-left: auto;
        margin-right: auto;
    }

    .category-detail-main > div > div.col-xl-3 {
        flex: 0 0 21.94%;
        max-width: 21.94%;
    }

    .site-footer > .container {
        max-width: 1360px;
    }


}

@media (min-width: 992px) {

    .home-unieke-collectie-homewear img {
        height: 662px;
        /*max-width: 557px;*/
        object-position: 43% 50%;
    }

    .home-categories img {
        min-height: 346px;
    }

    .home-ontspannen-genieten-row1-col2 img {
        height: 674px;
    }

    .home-ontspannen-genieten-row2-col1 img {
        height: 577px;
        object-position: 66% 50%;
    }

    .home-warmte-in-de-huiskamer-row1 img {
        height: 396px;
    }

    .home-warmte-in-de-huiskamer-row2 img {
        height: 490px;
    }

    .home-verpakken-col2 img {
        height: 674px;
    }

    /*.over-ons-rust-col2 img {
        height: 662px;
    }*/

    .over-ons-rust-col2 img {
        height: 500px;
    }

    .over-ons-kwaliteit img {
        height: 490px;
    }

    .over-ons-contact-col2 img {
        height: 674px;
    }

}

@media (max-width: 1800px) {

    .pl-5, .px-5 {
        padding-left: 40px !important;
    }

    .pr-5, .px-5 {
        padding-right: 40px !important;
    }

    .pl-md-5, .px-md-5 {
        padding-left: 40px !important;
    }

    .pr-md-5, .px-md-5 {
        padding-right: 40px !important;
    }

}

@media (max-width: 1439px) {

    .container {
        padding-left: 25px;
        padding-right: 25px;
    }



    .pl-5, .px-5 {
        padding-left: 40px !important;
    }

    .pr-5, .px-5 {
        padding-right: 40px !important;
    }

    .pl-md-5, .px-md-5 {
        padding-left: 40px !important;
    }

    .pr-md-5, .px-md-5 {
        padding-right: 40px !important;
    }


    .home-usps ul {
        margin-right: -40px;
    }

    .home-usps ul li {
        padding-right: 40px;
        font-size: 15px;
    }

    .over-ons-header > div > div > div > div:nth-child(1), .contact-header > div > div > div > div:nth-child(1), .algemene-voorwaarden-header > div > div > div > div:nth-child(1), .disclaimer-header > div > div > div > div:nth-child(1), .privacy-policy-header > div > div > div > div:nth-child(1) {
        left: 40px;
    }

}

@media (max-width: 1199px) {

    .pl-5, .px-5 {
        padding-left: 20px !important;
    }

    .pr-5, .px-5 {
        padding-right: 20px !important;
    }

    .pl-md-5, .px-md-5 {
        padding-left: 20px !important;
    }

    .pr-md-5, .px-md-5 {
        padding-right: 20px !important;
    }

    .product-detail-header {
        padding-top: 0px;
        padding-left: 15px;
        padding-right: 15px;
    }

    .alert + .product-detail-header {
        padding-top: 20px;
    }

    .mini-cart .badge-main {
        /*background-color: $brown-v1;*/
        background-color: #2b2b2b;
        color: $white;
        position: absolute;
        right: -2px;
        top: -14px;
    }


    header > div.px-5 {
        padding-left: 15px !important;
        padding-right: 15px !important;
    }

    .info-header .search-field {
        margin-bottom: 0px;
    }



    .home-usps ul {
        /*margin-right: -20px;*/
        margin-right: 0px;
    }

    /*.home-usps ul li {
    padding-right: 20px;
}*/

    .home-usps ul li {
        padding-right: 8px;
    }

    .container {
        max-width: 100%;
    }
}



@media (min-width: 768px) and (max-width: 991px) {
    .cart-page-cart-content .cart-page-cart-content-totals {
        flex: 0 0 50%;
        max-width: 50%;
        margin-left: auto;
        margin-right: 0;
    }
}

.webshop-categories-container > div > div {
    padding-left: 10px;
    padding-right: 10px;
}

@media (max-width: 991px) {

    .footer-col1 {
        text-align: center !important;
        margin-bottom: 40px;
    }

    .menuwebshop {
        margin-left: 15px;
    }

    .header-icons .menutext {
        font-size: 17px;
    }


    .webshop-categories-container {
        padding-left: 0px;
        padding-right: 0px;
    }
    .webshop-categories-container > div > div {
        padding-left: 10px;
        padding-right: 10px;
    }

    .home-merken {
        padding-top: 40px;
        padding-bottom: 40px;
    }

    .home-categories-title + .container {
        margin-top: -20px;
    }

    #related-products h2 {
        max-width: none;
    }

    .back-to-webshop {
        padding-top: 0px;
        margin-top: 0px !important;
        padding-bottom: 20px !important;
        margin-bottom: 20px !important;
    }

    #related-products h2 {
        font-size: 24px;
    }

    #related-products .related-products > div > div {
        margin-bottom: 15px !important;
    }

    #related-products .product-list-item .product-info {
        padding-bottom: 15px !important;
    }

    #properties-description .description h2 {
        font-size: 32px;
    }

    #properties-description {
        padding-top: 40px;
        padding-bottom: 40px;
    }

    .home-warmte-in-de-huiskamer-row2 {
        padding-top: 40px;
    }

    .qty-add-to-cart > div {
        width: 100%;
    }

    #product-detail .order-btn, #product-detail .proceed-button a, .proceed-button #product-detail a {
        padding: 0px 20px;
    }

    #product-detail .qty-add-to-cart .col-5,
    #product-detail .qty-add-to-cart .col-7 {
        flex: 0 0 50%;
        max-width: 50%;
    }

    #product-detail .qty-group .qty-field {
        width: auto;
        min-width: 34px;
        max-width: 100px;
    }

    .home-merken > div {
        padding-left: 15px;
    }

    /* #product-detail > div > div > div {
        padding-left: 0px !important;
        padding-right: 0px !important;
    }*/

    .category-detail-main .category-filters-container {
        padding: 30px 15px;
    }

    .catprod {
        padding-left: 15px !important;
        padding-right: 15px !important;
    }

    .filter-mob {
        padding-top: 40px;
    }

    .category-detail-main > div > div.col-xl-9 {
        padding-top: 40px;
    }

    .checkout-page .checkout-page-form {
        margin-top: 20px !important;
        margin-bottom: 20px !important;
    }

    .cart-totals-html-desktop {
        display: none;
    }

    .cart-totals-html-mobile {

    }

    .cart-totals-html-mobile .cart-totals {
        padding-left: 0px !important;
        padding-right: 0px !important;
    }

    .info-header > div {
        padding-bottom: 0px !important;
    }

    .category-detail-header {
        padding-top: 0px;
        padding-bottom: 20px;
    }

    .pl-5, .px-5 {
        padding-left: 15px !important;
    }

    .pr-5, .px-5 {
        padding-right: 15px !important;
    }

    .pl-md-5, .px-md-5 {
        padding-left: 15px !important;
    }

    .pr-md-5, .px-md-5 {
        padding-right: 15px !important;
    }


    .searchpage-content {
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-wrap: wrap;
        flex-wrap: wrap;
        -webkit-align-content: center;
        align-content: center;
        -webkit-align-items: center;
        align-items: center;
        justify-content: center;
        -webkit-justify-content: center;
        min-height: 500px;
    }


    .searchpage-content > div {
        padding-top: 40px;
        padding-bottom: 40px;
    }


    .over-ons-header h1, .contact-header h1, .algemene-voorwaarden-header h1, .disclaimer-header h1, .privacy-policy-header h1 {
        font-size: 40px;
        line-height: 60px;
    }

    .home-unieke-collectie-homewear {
        padding-top: 40px;
        padding-bottom: 40px;
    }

    .home-unieke-collectie-homewear-col2 > div {
        padding-top: 40px;
    }

    .privacyverklaring-main-content-table-desktop {
        display: none;
    }

    .home-usps h2, .home-categories-title h2, .webshop-categories-title h2 {
        font-size: 95.39px;
        line-height: 83.6px;
        letter-spacing: 8px;
    }

    .home-merken h2 {
        font-size: 79.76px;
        line-height: 68.91px;
        letter-spacing: 8px;
    }

    .home-usps h2 {
        margin-bottom: 0px;
    }

    .home-usps ul {
        padding-left: 0px;
        padding-right: 0px;
        margin-bottom: 0px;
    }

    .home-categories-section {
        padding-top: 40px;
        padding-bottom: 40px;
    }

    .home-ontspannen-genieten {
        padding-top: 40px;
        padding-bottom: 40px;
    }

    .home-ontspannen-genieten-row1 {
        padding-bottom: 20px;
    }

    .home-ontspannen-genieten-row2 {
        padding-top: 20px;
    }

    .home-categories img {
        max-height: 300px;
    }

    .home-categories-title + .container .col-lg-4 {
        margin-top: 30px;
    }


    .over-ons-rust {
        padding-top: 40px;
        padding-bottom: 40px;
    }

    .over-ons-kwaliteit {
        padding-top: 40px;
        padding-bottom: 20px;
    }

    .over-ons-kwaliteit > div > div > div:nth-child(2) {
        padding-top: 40px;
    }

    .over-ons-slider-section {
        padding-bottom: 40px;
    }

    .over-ons-contact {
        padding-top: 40px;
        padding-bottom: 40px;
    }

    .over-ons-contact-col2 {
        padding-top: 20px;
    }

/*    .over-ons-header > div > div > div > div:nth-child(1), .contact-header > div > div > div > div:nth-child(1), .algemene-voorwaarden-header > div > div > div > div:nth-child(1), .disclaimer-header > div > div > div > div:nth-child(1), .privacy-policy-header > div > div > div > div:nth-child(1) {
        z-index: 1;
        position: absolute;
        top: 66%;
    }*/

    .over-ons-header > div > div > div > div:nth-child(1), .contact-header > div > div > div > div:nth-child(1), .algemene-voorwaarden-header > div > div > div > div:nth-child(1), .disclaimer-header > div > div > div > div:nth-child(1), .privacy-policy-header > div > div > div > div:nth-child(1) {
        left: 30px;
    }

    .over-ons-usps ul {
        padding-left: 0px;
    }

    .over-ons-usps ul li + li {
        margin-top: 15px;
    }

    .over-ons-rust-col2 {
        padding-top: 40px;
    }

    .over-ons-header img,
    .contact-header img,
    .algemene-voorwaarden-header img,
    .disclaimer-header img,
    .privacy-policy-header img {
        min-height: 300px;
        object-fit:cover;
    }


    .cart-contents .col-md-8 {
        padding-top: 40px;
    }

    .cart-page-cart-content .cart-totals {
        padding-left: 0px !important;
        margin-top: 40px;
    }

    .cart-page-cart-content .cart-content-main,
    .cart-page-cart-content .cart-content-proceed {
        padding-left: 15px;
        padding-right: 15px;
    }

    .cart-page-cart-content .cart-content-header {border-bottom: none;}


}

@media (max-width: 959px) {

    .shoppingcartnav .cartnavsteps > div {
        padding-left: 0px;
        padding-right: 0px;
    }

    header > div.py-4 {
        padding-top: 20px !important;
        padding-bottom: 0px !important;
    }

    header .mini-cart {
        position: relative;
        top: -25px;
        left: -30px;
    }

    header .search-field {
        position: relative;
        top: -25px;
        left: -30px;
    }

    .over-ons-slider-section .uk-slidenav {
        background: #FFF;
    }

}



@media (min-width: 640px) and (max-width: 991px) {

/*    .home-usps ul li {
        padding-top: 20px;
    }*/

    .home-usps-col1, .home-usps-col2 {
        flex: 0 0 100%;
        max-width: 100%;
    }
}



@media (max-width: 767px) {

    .home-podium-op-maat > div > div > div:nth-child(2) {
        padding-top: 40px;
    }


    /*    header > div > div > div {
        text-align: center;
    }

*/
    .home-main > div > div > div:nth-child(2) {
        margin-top: 40px;
    }

    .home-bottom > div > div > div:nth-child(2) {
        margin-top: 40px;
    }

    section#related-products {
        padding-top: 0px !important;
    }

    .home-unieke-collectie-homewear-col2 > div,
    .home-warmte-in-de-huiskamer-row1-col1 > div,
    .home-ontspannen-genieten-row1-col1 > div,
    .home-ontspannen-genieten-row2-col2 > div,
    .home-warmte-in-de-huiskamer-row1-col1 > div,
    .home-ontspannen-genieten-row1-col1 > div,
    .home-warmte-in-de-huiskamer-row2-col2 > div,
    .home-ontspannen-genieten-row2-col2 > div,
    .home-verpakken-col1 > div,
    .over-ons-rust h2,
    .over-ons-rust p,
    .over-ons-kwaliteit h2,
    .over-ons-kwaliteit p {
        max-width: none;
    }

    .home-unieke-collectie-homewear-col2 > div {
        padding-left: 0px;
        paddding-right: 0px;
    }

    .over-ons-contact p {
        max-width: none;
    }

    .order-btn, .proceed-button a {
        padding: 10px 20px;
    }

    #product-detail .order-btn, #product-detail .proceed-button a, .proceed-button #product-detail a {
        padding: 0px 20px;
    }

    .home-merken h2 {
        font-size: 69.76px;
        line-height: 58.91px;
        letter-spacing: 4px;
    }

    .home-usps h2, .home-categories-title h2, .webshop-categories-title h2 {
        font-size: 65.39px;
        line-height: 53.6px;
        letter-spacing: 4px;
    }

    .contact-main > div > div > div:nth-child(2) {
        padding-top: 40px;
    }



    h2 {
        font-size: 32px;
        letter-spacing: 0px;
    }

    h3 {
        letter-spacing: 0px;
    }


    .algemene-voorwaarden-main h2, .disclaimer-main h2, .privacy-policy-main h2 {font-size: 24px;}
    .algemene-voorwaarden-main h3, .disclaimer-main h3, .privacy-policy-main h3 {font-size: 20px;letter-spacing: 0px;}

    .algemene-voorwaarden-main, .disclaimer-main, .privacy-policy-main, .contact-main {
        padding: 40px 10px;
        box-sizing: border-box;
    }

    #product-detail > div > div {
        padding-top: 40px !important;
        padding-bottom: 40px !important;
    }

    .cart-content-section {
        padding-top: 40px;
        padding-bottom: 40px;
    }

    .header-logo {
        position: relative;
        top: -28px;
        max-width: 220px;
    }


    .searchpage-content {
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-wrap: wrap;
        flex-wrap: wrap;
        -webkit-align-content: center;
        align-content: center;
        -webkit-align-items: center;
        align-items: center;
        justify-content: center;
        -webkit-justify-content: center;
        min-height: 300px;
    }

    .over-ons-header h1, .contact-header h1, .algemene-voorwaarden-header h1, .disclaimer-header h1, .privacy-policy-header h1 {
        font-size: 24px;
        line-height: 44px;
    }

    .home-categories .txt {
        padding: 10px;
        font-size: 24px;
    }


    .footer-col1, .footer-col2 {
        flex: 0 0 50%;
        max-width: 50%;
    }

    .footer-col3 {
        flex: 0 0 100%;
        max-width: 100%;
        justify-content: flex-start;
        -webkit-justify-content: flex-start;
    }

    .footer-col1, .footer-col2, .footer-col3, .footer-col4 {
        flex: 0 0 100%;
        max-width: 100%;
        justify-content: center;
        -webkit-justify-content: center;
        text-align: center !important;
    }

    .home-unieke-collectie-homewear img {
        position: relative;
        top: 0%;
        left: 0%;
        -webkit-transform: translate(0%, 0%);
        -moz-transform: translate(0%, 0%);
        -ms-transform: translate(0%, 0%);
        -o-transform: translate(0%, 0%);
        transform: translate(0%, 0%);
        z-index: 2;
        max-height: 662px;
        max-width: 557px;
        object-fit: cover;
    }

    .home-ontspannen-genieten-row1-col2 img {
        position: relative;
        top: 0%;
        right: 0%;
        -webkit-transform: translate(0%, 0%);
        -moz-transform: translate(0%, 0%);
        -ms-transform: translate(0%, 0%);
        -o-transform: translate(0%, 0%);
        transform: translate(0%, 0%);
        z-index: 2;
        max-height: 674px;
        max-width: 577px;
        object-fit: cover;
    }

    .home-ontspannen-genieten-row2-col1 img {
        position: relative;
        top: 0%;
        left: 0%;
        -webkit-transform: translate(0%, 0%);
        -moz-transform: translate(0%, 0%);
        -ms-transform: translate(0%, 0%);
        -o-transform: translate(0%, 0%);
        transform: translate(0%, 0%);
        z-index: 2;
        max-height: 577px;
        max-width: 465px;
        object-fit: cover;
    }

    .home-verpakken-col2 img {
        position: relative;
        top: 0%;
        right: 0%;
        -webkit-transform: translate(0%, 0%);
        -moz-transform: translate(0%, 0%);
        -ms-transform: translate(0%, 0%);
        -o-transform: translate(0%, 0%);
        transform: translate(0%, 0%);
        z-index: 2;
        max-height: 674px;
        max-width: 577px;
        object-fit: cover;
    }

    .over-ons-rust-col2 img {
        position: relative;
        top: 0%;
        left: 0%;
        -webkit-transform: translate(0%, 0%);
        -moz-transform: translate(0%, 0%);
        -ms-transform: translate(0%, 0%);
        -o-transform: translate(0%, 0%);
        transform: translate(0%, 0%);
        max-height: 662px;
        max-width: 557px;
        object-fit: cover;
    }

    .over-ons-contact-col2 img {
        position: relative;
        top: 0%;
        left: 0%;
        -webkit-transform: translate(0%, 0%);
        -moz-transform: translate(0%, 0%);
        -ms-transform: translate(0%, 0%);
        -o-transform: translate(0%, 0%);
        transform: translate(0%, 0%);
        max-height: 674px;
        max-width: 100%;
        object-fit: cover;
    }

    .home-ontspannen-genieten-row2 > div:nth-child(1) {order: 2;-webkit-order: 2;}
    .home-ontspannen-genieten-row2 > div:nth-child(2) {order: 1;-webkit-order: 1;}

    .home-warmte-in-de-huiskamer-row2 > div:nth-child(1) {order: 2;-webkit-order: 2;}
    .home-warmte-in-de-huiskamer-row2 > div:nth-child(2) {order: 1;-webkit-order: 1;}

    .home-warmte-in-de-huiskamer-row1-col1 > div, .home-ontspannen-genieten-row1-col1 > div {
        padding: 0px;
    }

    .home-categories-title + .container .col-lg-4 {
        margin-top: 30px;
    }

    .home-categories-title + .container .col-lg-4 {
        margin-top: 20px;
    }

    .home-warmte-in-de-huiskamer {
        padding-top: 40px;
        padding-bottom: 40px;
    }

    .home-warmte-in-de-huiskamer-row1-col2 {
        text-align: center;
        padding-top: 40px;
    }

    .home-ontspannen-genieten-row1-col2 {
        padding-top: 20px;
    }

    .home-warmte-in-de-huiskamer-row2 > div:nth-child(2) {
        padding-top: 40px;
    }

    .home-warmte-in-de-huiskamer-row2-col2 > div, .home-ontspannen-genieten-row2-col2 > div {
        padding: 0px;
    }

    .home-warmte-in-de-huiskamer-row2 > div:nth-child(1) {
        padding-top: 20px;
    }

    .home-verpakken {
        padding-top: 40px;
        padding-bottom: 40px;
    }

    .home-verpakken-col1 > div {
        padding: 0px;
    }

    .home-verpakken-col2 {
        padding-top: 20px;
    }

    .cart-contents .col-md-8 {
        padding-top: 40px;
    }

    .cart-page-cart-content .cart-totals {
        padding-left: 0px !important;
        margin-top: 40px;
    }

    .cart-page-cart-content .cart-content-main,
    .cart-page-cart-content .cart-content-proceed {
        padding-left: 15px;
        padding-right: 15px;
    }

    .cart-page-cart-content .cart-content-header {border-bottom: none;}

    /*.home-categories .col-lg-8 .row + .row {
        margin-top: 0px;
    }

    .home-categories .col-lg-8 .row:nth-child(2) > div:nth-child(2) {
        margin-bottom: 20px;
    }

    .home-categories-title + .container .col-lg-4 {
        margin-top: 0px;
    }*/

    .home-unieke-collectie-homewear img,
    .home-ontspannen-genieten-row1-col2 img,
    .home-ontspannen-genieten-row2-col1 img,
    .home-warmte-in-de-huiskamer-row1 img,
    .home-warmte-in-de-huiskamer-row2 img,
    .home-verpakken-col2 img,
    .over-ons-rust-col2 img,
    .over-ons-kwaliteit img {
        max-width: none;
    }


}



@media (max-width: 699px) {
    .form-steps li {
        margin-right: 0px;
        display: block;
    }

    .form-steps li + svg, .form-steps li + i {
        display: none;
    }
}


@media (max-width: 639px) {


    .info-header .header-logo {
        max-width: 120px;
    }

    .info-header > div > div > div:nth-child(1) {
        padding-right: 0px;
    }

    .info-header > div > div > div:nth-child(2) {
        padding-left: 0px;
    }

    .header-icons .search-field {
        margin-left: 15px;
        margin-right: 15px;
    }


    .home-usps ul li {
        /*    padding-top: 15px;
    padding-bottom: 15px;*/
        padding-top: 5px;
        padding-bottom: 5px;
        padding-right: 20px;
        /*display: block;*/
    }

    .home-header-logo > div {
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-wrap: wrap;
        flex-wrap: wrap;
        -webkit-align-content: center;
        align-content: center;
        -webkit-align-items: center;
        align-items: center;
        justify-content: flex-start;
        -webkit-justify-content: flex-start;
    }


}


@media (max-width: 600px) {
    .checkout-page .butn-next {
        width: 100%;margin-bottom: 30px;
    }
    .checkout-page .butn-light {
        width: 100%;
    }

    .catprod {
        margin-bottom: 15px !important;
    }

    .category-detail-main .product-info {
        padding-bottom: 15px !important;
    }

}


@media (max-width: 480px) {

    .header-home .header-icons .menutext {
        display: block;
    }

    .home-categories .col-lg-8 .row + .row {
        margin-top: 0px;
    }

    .home-categories .col-lg-8 .row:nth-child(2) > div:nth-child(2) {
        margin-bottom: 20px;
    }

    .home-categories-title + .container .col-lg-4 {
        margin-top: 20px;
    }

    .home-categories-title + .container > .row > div > .row {
        margin-top: 0px !important;
    }

    .home-categories-section > div > div > div > section > div:nth-child(2) > div > div.col-lg-8 > div:nth-child(2) {
        margin-top: 0px !important;
    }

    .home-categories .col-lg-8 .row:nth-child(2) > div:nth-child(1) {
        order: 2;-webkit-order: 2;
    }

    .home-categories .col-lg-8 .row:nth-child(2) > div:nth-child(2) {
        order: 1;-webkit-order: 1;
    }

    .alert .btn {
        margin-left: auto !important;
        margin-right: auto !important;
        margin-top: 30px !important;
        display: block;
    }

    #product-detail .product-usps > div {
        padding-left: 0px;
        padding-right: 0px;
    }

    #product-detail .product-usps ul li {
        font-size: 15px;
    }

    .qty-add-to-cart > div {
        max-width: none;
        width: 100%;
    }

    #product-detail .qty-add-to-cart .col-5 {
        flex: 0 0 100%;
        max-width: 100%;
    }

    #product-detail .qty-add-to-cart .col-7 {
        flex: 0 0 100%;
        max-width: 100%;
        margin-top: 30px;
    }


    /*  .header-logo {
        position: relative;
        top: -45px;
        max-width: 180px;
        left: -15px;
    }*/

    .header-home .header-logo {
        position: relative;
        top: -50px;
        max-width: 177px;
        left: -45px;
    }

    .header-home > div.py-4 {
        padding-top: 40px !important;
        padding-bottom: 60px !important;
    }

    .header-home #offcanvas-menu .header-logo {
        position: relative;
        top: 0px;
        max-width: 177px;
        left: 0px;
    }


    .info-header #offcanvas-menu .header-logo {
        position: relative;
        top: 0px;
        max-width: 177px;
        left: 0px;
    }

    .footer-col1, .footer-col2 {
        flex: 0 0 100%;
        max-width: 100%;
    }
    .footer-col2 {
        justify-content: flex-start;
        -webkit-justify-content: flex-start;
        margin-top: 2.5rem !important;
    }

    .home-categories-section .home-categories .col-lg-8 .col-6 {
        flex: 0 0 100%;
        max-width: 100%;
    }

    .home-categories-section .home-categories .brown-bg {
        margin-bottom: 20px;
    }

    .home-categories-section .home-categories .darkgrey-bg {
        margin-top: 20px;
    }

    .searchpage-content .search-field-input,
    .searchpage-content .do-search-button {
        display: block;
        width: 100%;
    }

    .searchpage-content .search-field-input input {
        min-width: auto;
        width: 100%;
    }


}




.home-categories-title + .container .col-lg-4 > div {
    height: 100%;
}


.home-categories-title + .container > .row {
    margin-left: -10px;
    margin-right: -10px;
}

.home-categories-title + .container > .row > div {
    padding-left: 10px;
    padding-right: 10px;
}

.home-categories-title + .container > .row > div > .row {
    margin-left: -10px;
    margin-right: -10px;
}

.home-categories-title + .container > .row > div > .row > div {
    padding-left: 10px;
    padding-right: 10px;
}

.home-categories .col-lg-8 .row + .row {
    margin-top: 20px !important;
}

.home-categories .col-lg-4 a {
    width: 100%;
    height: 100%;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-align-content: center;
    align-content: center;
    -webkit-align-items: center;
    align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
    flex-direction: column;
    -webkit-flex-direction: column;
    /*background: $darkgrey-v1;*/
    color: $darkgrey-v1;
    font-family: $font5;
    font-weight: normal;
    font-style: normal;
    font-size: 36px;
    line-height: 36.65px;
    text-transform: uppercase;
    background: transparent;
    transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
    min-height: 250px;
    /*padding-top: 20px;
    padding-bottom: 20px;*/
    box-sizing: border-box;
}

.home-categories .col-lg-4 a:hover,
.home-categories .col-lg-4 a:focus {
    text-decoration: none;
    background: $brown-v1;
}

.home-categories .col-lg-4 a .txt-overlay {
    display: none;
}

.home-categories .col-lg-4 a:hover .txt-overlay,
.home-categories .col-lg-4 a:focus .txt-overlay {
    display: flex;
    flex-direction: column;
    -webkit-flex-direction: column;
}

.home-categories .col-lg-4 a img {
    z-index: 0;
    object-position: 60% 50%;
}

.home-categories .col-lg-4 a:hover img,
.home-categories .col-lg-4 a:focus img {
    z-index: -1;
}

.home-categories .txt {
    max-width: 250px;
    text-align: center;
}

.home-categories .col-lg-4 .txt-overlay {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    text-align: center;
}

.home-categories .col-lg-4 .txt-overlay .shop-btn {
    font-family: $font1;
    font-size: 17px;
    color: $darkgrey-v1;
    border: 1px solid $darkgrey-v1;
    display: inline-block;
    padding: 0 24px;
    margin-top: 20px;
}

.home-categories .col-lg-4 .txt-overlay .shop-btn:hover,
.home-categories .col-lg-4 .txt-overlay .shop-btn:focus {
    background-color: $darkgrey-v1;
    color: $white;
}

.home-categories img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.home-categories .col-lg-8 .row + .row {margin-top: 30px;}

.home-ontspannen-genieten {
    /*background: url('/images/bg.jpg') no-repeat 50% 50%;*/
    background: url('/images/fabric-textured-background.jpg') no-repeat 50% 50%;
    background-size: cover;
    padding-top: 150px;
    padding-bottom: 100px;
}

.home-ontspannen-genieten-row1 {padding-bottom: 170px;}
.home-ontspannen-genieten-row2 {padding-top: 170px;}

.home-ontspannen-genieten-row1-col2 img {
    position: absolute;
    top: 33.33%;
    right: 0%;
    -webkit-transform: translate(0%, -50%);
    -moz-transform: translate(0%, -50%);
    -ms-transform: translate(0%, -50%);
    -o-transform: translate(0%, -50%);
    transform: translate(0%, -50%);
    z-index: 2;
    max-height: 674px;
    max-width: 577px;
    object-fit: cover;
}

.home-ontspannen-genieten-row2-col1 img {
    position: absolute;
    top: 67.666%;
    /*left: 0%;*/
    left: 15px;
    -webkit-transform: translate(0%, -50%);
    -moz-transform: translate(0%, -50%);
    -ms-transform: translate(0%, -50%);
    -o-transform: translate(0%, -50%);
    transform: translate(0%, -50%);
    z-index: 2;
    max-height: 577px;
    max-width: 465px;
    object-fit: cover;
}

.home-warmte-in-de-huiskamer {
    padding-top: 105px;
    padding-bottom: 140px;
}

.home-warmte-in-de-huiskamer-row1-col2 {
    text-align: right;
}

.home-warmte-in-de-huiskamer-row1 a {
    display: inline-block;
    background-color: $brown-v1;
    color: $white;
    font-family: $font1;
    font-size: 17px;
    padding: 0 28px;
    text-transform: uppercase;
    transition: all 0.25s ease;
    -webkit-transition: all 0.25s ease;
}

.home-warmte-in-de-huiskamer-row1 a:hover,
.home-warmte-in-de-huiskamer-row1 a:focus {
    background-color: $brown-v2;
    text-decoration: none;
}

.home-warmte-in-de-huiskamer-row1 img {
    max-height: 396px;
    max-width: 409px;
    object-fit: cover;
}

.home-warmte-in-de-huiskamer-row2 {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-align-content: center;
    align-content: center;
    -webkit-align-items: center;
    align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
}

.home-warmte-in-de-huiskamer-row2 img {
    max-height: 490px;
    max-width: 467px;
    object-fit: cover;
    object-position: 90% 50%;
}

.home-warmte-in-de-huiskamer p {
    color: $darkgrey-v2;
}

.home-warmte-in-de-huiskamer-row1-col1, .home-ontspannen-genieten-row1-col1 {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-align-content: center;
    align-content: center;
    -webkit-align-items: center;
    align-items: center;
    justify-content: flex-start;
    -webkit-justify-content: flex-start;
}

.home-warmte-in-de-huiskamer-row1-col1 > div, .home-ontspannen-genieten-row1-col1 > div{
    max-width: 460px;
    padding: 20px;
    box-sizing: content-box;
}


.home-warmte-in-de-huiskamer-row2-col2, .home-ontspannen-genieten-row2-col2 {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-align-content: center;
    align-content: center;
    -webkit-align-items: center;
    align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
}

.home-warmte-in-de-huiskamer-row2-col2 > div, .home-ontspannen-genieten-row2-col2 > div {
    max-width: 460px;
    padding: 20px;
    box-sizing: content-box;
}

.home-ontspannen-genieten-row2-col2 > div {
    max-width: 500px;
    padding: 20px;
    box-sizing: content-box;
}

.home-verpakken {
    /*background: url('/images/footer-bg.jpg') no-repeat 50% 50%;*/
    background: url('/images/fabric-textured-background.jpg') no-repeat 50% 50%;
    background-size: cover;
    padding-top: 130px;
    padding-bottom: 110px;
}


.home-verpakken-col1 > div {
    max-width: 480px;
    text-align: justify;
    padding: 20px;
    box-sizing: content-box;
}

.home-verpakken-col1 p {margin-bottom: 0px;}

.home-verpakken-col2 > div {
    position: relative;
    z-index: 1;
    height: 100%;
}

.home-verpakken-col2 img {
    position: absolute;
    top: calc(50% - 10px);
    right: 0%;
    -webkit-transform: translate(0%, -50%);
    -moz-transform: translate(0%, -50%);
    -ms-transform: translate(0%, -50%);
    -o-transform: translate(0%, -50%);
    transform: translate(0%, -50%);
    z-index: 2;
    max-height: 674px;
    max-width: 577px;
    object-fit: cover;
}

.home-merken h2 {
    background: -webkit-linear-gradient(#f0e9e3, #fff 75%);
    background: linear-gradient(#f0e9e3, #fff 75%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: $font2;
    font-size: 179.76px;
    line-height: 168.91px;
    letter-spacing: 20px;
    margin-left: -10px;
}

.home-merken {
    padding-top: 50px;
    padding-bottom: 100px;
}

.home-merken > div {
    padding-left: 0px;
}

.home-brands > .container {
    max-width: 880px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
}

.home-brands .row .row + .row {
    margin-top: 90px;
}

.home-brands a {
    pointer-events: none;
    cursor: default;
}

.no-products-added-to-cart .btn {
    display: inline-block;
    background-color: $brown-v1;
    color: #fff;
    font-family: "Montserrat-Light", sans-serif;
    font-size: 17px;
    padding: 14px 28px;
    text-transform: uppercase;
    transition: all 0.25s ease;
    -webkit-transition: all 0.25s ease;
}

.no-products-added-to-cart .btn:hover,
.no-products-added-to-cart .btn:focus {
    background-color: $brown-v2;
    text-decoration: none;
}



.category-detail .product-list-item {
    text-align: center;
    border: none;
}

.category-detail .product-list-item:hover {
    background-color: transparent;
}

.category-detail .product-list-item:before {
    display: none;
}

.category-detail .product-list-item .product-info:before {
    display: none;
}

.category-detail .product-title {
    font-family: $font1;
    color: $darkgrey-v1;
    font-size: 18px;
}

.category-detail .product-list-item .product-info .product-price {
    font-family: $font2;
    color: $darkgrey-v1;
    font-size: 20px;
}


.categories-filter-title h4 {
    font-family: $font5;
    font-weight: normal;
    font-style: normal;
    font-size: 18px;
    line-height: 36.65px;
    color: $brown-v1;
    /*border-bottom: 1px solid rgba(18, 14, 14, 0.2);*/
    text-transform: uppercase;
}



#product-detail {}

#product-detail > div > div {
    padding-top: 20px !important;
}

#product-detail .productgalleryslider {
    padding-left: 0px;
    padding-right: 10px;
}

#product-detail .productgallerymainimg {
    padding-left: 10px;
}

#product-detail .slick-slider .slick-track {
    margin-top: -5px;
}

#product-detail .main-image {
    width: 100%;
    height: 100%;
    /*max-height: 460px;*/
    max-height: 529px;
    text-align: center;
    border: 1px solid #C4C4C4;
    max-width: 529px;
    margin-left: 0px;
    margin-right: auto;
}

#product-detail .image-slide {
    max-width: 529px;
    margin-left: 0px;
    margin-right: auto;
}

#product-detail .main-image img {
    width: 100%;
    height: 100%;
    max-height: 460px;
    /*object-fit: cover;*/
    object-fit: contain;
}

#product-detail .product-title {
    font-family: $font2;
    font-size: 30px;
    color: $brown-v1;
    text-align: left;
    letter-spacing: -1px;
}

#product-detail .brand-name {
    font-family: $font2;
    color: $brown-v2;
    font-size: 25px;
}

#product-detail .product-detail-price {
    /*font-family: $font2;
    color: $darkgrey-v1;
    font-size: 20px;*/
    font-family: 'Roboto';
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 29px;
    color: #2B2B2B;
}

#product-detail .description-top {
    font-family: $font1;
    color: $darkgrey-v1;
    font-size: 18px;
    line-height: 36px;
}

#product-detail .description-top p {
    margin-bottom: 0px;
}

#product-detail .description-top-link {
    font-family: $font1;
    color: $darkgrey-v1;
    font-size: 16px;
    line-height: 26px;
    border-bottom: 1px solid rgba(39,33,30,0.251);
    display: inline-block;
}

#product-detail .description-top-link:hover,
#product-detail .description-top-link:focus {
    border-bottom: 1px solid rgba(39,33,30,1);
    text-decoration: none;
}

#product-detail .variants .variant:hover .image-container,
#product-detail .variants .variant.active-variant .image-container {
    border: 2px solid rgba(39,33,30,0.251);
}

#product-detail .variants .variant {
    padding-left: 0.25rem !important;
    padding-right: 0.25rem !important;
    padding-top: 0.25rem !important;
}


#product-detail .variants .image-container img {
    width: 100%;
    height: 100%;
    max-width: 96px;
    max-height: 96px;
}

#product-detail .qty-group input {
    border: none !important;
}

#product-detail .qty-group {
    border: 1px solid #C4C4C4;
    box-sizing: border-box;
    height: 45px;
    min-width: 117px;
}

.qty-add-to-cart-max {
    max-width: 370px;
}

.qty-group .qty-up {
    position: absolute;
    bottom: 4px;
    right: 7px;
}

/*#product-detail .qty-group .qty-down {
    position: relative;
    bottom: 0px;
    left: 0px;
    font-family: $font1;
    color: $darkgrey-v1;
    font-size: 14px;
}

#product-detail .qty-group .qty-up {
    position: relative;
    bottom: 0px;
    right: 0px;
    font-family: $font1;
    color: $darkgrey-v1;
    font-size: 14px;
}*/

/*
#product-detail .qty-group .qty-field {
    border: 1px solid rgb(214, 214, 214) !important;
    width: 120px;
    height: 34px !important;
    border-radius: 0px !important;
    margin-left: 10px;
    margin-right: 10px;
}
*/

#product-detail .order-btn {
    /* background: $brown-v1;
    color: $white;
    font-family: $font1;
    font-size: 17px;
    text-transform: uppercase;
    border-radius: 0px;
    padding: 0 35px;*/
    font-family: 'Roboto';
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    color: #FFFFFF;
    padding: 0px 35px;
    display: inline-block;
    transition: all 0.25s ease;
    -webkit-transition: all 0.25s ease;
    height: 45px;
    line-height: 45px;
    margin-left: 10px;
}

#product-detail .order-btn:hover,
#product-detail .order-btn:focus {
    /*background: $brown-v2;*/
    background: #2B2B2B;
}

#product-detail  .qty-add-to-cart > div {
    margin-left: -15px;
    margin-right: -15px;
}


#product-detail .qty-add-to-cart .col-5 {
    padding-right: 0px;
}

#product-detail .qty-add-to-cart p {
    margin-top: 0px;
    margin-bottom: 0px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 26px;
    color: #000;
}

#product-detail .productconfiguration-name {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 32px;
    color: #1E1E20;
    margin-top: 20px;
    display: inline-block;
}

.product_configurations select {
    border: 1px solid #C4C4C4;
    box-sizing: border-box;
    height: 45px;
    padding-left: 15px;
    padding-right: 15px;
    max-width: 100%;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: transparent;
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAkAAAAJCAYAAADgkQYQAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAABASURBVHgBrcrRCQAgCARQR3CERnDUNmiERmi1FArC1B89OJDzAVSmc9H5yT7kIO4yIJ6d7qDhBzRsHnjhjEAuG3PgCFkVC6U1AAAAAElFTkSuQmCC');
    padding-right: 1rem;
    background-repeat: no-repeat;
    background-position-x: 96%;
    background-position-y: 18px;
    background-size: 9px;
}

#product-detail .order-btn img {
    margin-right: 10px;
}

.qty-add-to-cart .qty-group {

}

#product-detail .variants {}

#product-detail .product-usps {

}

#product-detail .product-usps ul {
    list-style: none;
    padding-left: 0px;
}

#product-detail .product-usps li svg {
    margin-right: 15px;
    font-weight: bold;
}

#properties-description {
    /*background: url('/images/bg.jpg') no-repeat 50% 50%;*/
    background: url('/images/fabric-textured-background.jpg') no-repeat 50% 50%;
    background-size: cover;
    padding-top: 90px;
    padding-bottom: 80px;
}

#properties-description .properties h2 {
    font-family: $font2;
    font-size: 19.98px;
    line-height: 39.96px;
    color: $brown-v1;
    text-transform: none;
    margin-top: 0px !important;
    margin-bottom: 0px !important;
}

#properties-description .properties h2 + p {
    margin-top: 0px !important;
}

#properties-description .description h2 {
    font-family: $font5;
    font-weight: normal;
    font-style: normal;
    font-size: 38px;
    line-height: 39.96px;
}

#properties-description .description span + h2 {
    margin-top: 5px !important;
}

.product-list-item .image-container {
    padding-bottom: inherit;
}

.product-list-item .image-container img {
    /*max-width: 240px;
    max-height: 240px;*/
    width: 100%;
    height: 100%;
    object-fit: contain;
    position: relative;
    height: 300px;
}


#related-products h2 {
    max-width: 480px;
    font-weight: 400;
}

section#related-products {
    background: $white;
    padding-bottom: 0px !important;
}

#related-products .related-products {
    padding-top: 0px !important;
    padding-bottom: 10px !important;
}

#related-products .product-list-item {
    border: 1px solid $white;
}

#related-products .product-list-item {
    border: 1px solid #ececec;
}

#related-products .product-list-item .product-info:before {
    background-color: $white;
}

#related-products .product-list-item .image-container {
    padding-bottom: inherit;
}

#related-products .product-list-item .image-container img {
    max-width: 240px;
    max-height: 240px;
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: relative;
}

#related-products .product-list-item:before {
    display: none !important;
}

#related-products .product-list-item .product-info {
    text-align: center;
}

.category-detail-main .product-info {
    text-align: center;
}

.category-detail-main .product-info  .product-price {
    font-family: $font2;
    font-size: 20px;
    color: $darkgrey-v1;
}

#related-products .product-list-item .product-info .product-title {
    font-family: $font1;
    color: $darkgrey-v1;
    max-width: 200px;
    margin-left: auto;
    margin-right: auto;
}


.back-to-webshop {
    padding-top: 20px;
    /*margin-top: 0px !important;*/
    margin-top: 3rem !important;
    padding-bottom: 20px !important;
}

.back-to-webshop a {
    background: $brown-v1;
    color: #fff;
    font-family: $font1;
    font-size: 17px;
    text-transform: uppercase;
    border-radius: 0px;
    padding: 0 43px;
    display: inline-block;
    transition: all 0.25s ease;
    -webkit-transition: all 0.25s ease;
}

.back-to-webshop a:hover,
.back-to-webshop a:focus {
    background: $brown-v2;
    text-decoration: none;
}


.privacyverklaring-main-content-table-desktop {
    overflow-x: auto;
}

.privacyverklaring-main-content-table-desktop table {
    border-collapse: collapse;
}

.privacyverklaring-main-content-table-desktop table tr {
    border-bottom: 1px solid #ccc;
}

.privacyverklaring-main-content-table-desktop table tr th,
.privacyverklaring-main-content-table-desktop table tr td {
    text-align: left;
    padding: 5px 15px 5px 5px;
}

.pager {
    margin-bottom: 40px;
}

.pager a {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    font-size: 20px;
    line-height: 24px;
    /* identical to box height */
    text-align: center;
    color: #2B2B2B;
}

.pager .my-active span {
    font-family: $font2;
    color: $brown-v1;
    font-size: 20px;
    font-weight: bold;
}

.pager .prev-btn a, .pager .next-btn a {
    background: #74C6C7;
    color: #FFF;
    padding: 8px 15px;
    box-sizing: border-box;
}

.pager .prev-btn a:hover, .pager .next-btn a:hover,
.pager .prev-btn a:focus, .pager .next-btn a:focus {
    background: #2B2B2B;
}

.pager .disabled {
    display: none;
}

/*.category-detail-header,*/ .cartnav {
                                 /*background: url('/images/bg.jpg') no-repeat 50% 50%;*/
                                 background: url('/images/fabric-textured-background.jpg') no-repeat 50% 50%;
                                 background-size: cover;
                                 padding-top: 40px;
                                 padding-bottom: 40px;
                             }

.checkout-title-container {
    position: relative;
    z-index: 0;
    background: #74C6C7;
    overflow: hidden;
}

.checkout-top-usps {
    background: #2B2B2B;
}




.category-detail-header h1, .product-detail-header h1, .checkout-title-container h1 {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: bold;
    font-size: 48px;
    line-height: 52px;
    color: #FFFFFF;
    text-transform: none;
}

.product-detail-header h1 {
    display: block;
}

.category-detail-header a,
.category-detail-header a + svg,
.category-detail-header i,
.product-detail-header a,
.product-detail-header a + svg,
.product-detail-header i {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 30px;
    color: #FFFFFF;
}

.category-detail-header *,
.product-detail-header * {
    color: #FFFFFF;
}

.category-detail-header i,
.product-detail-header i {
    font-family: "Font Awesome 5 Pro";
    font-weight: 300;
}



.category-detail-header > div:nth-child(1) {
    position: relative;
    z-index: 0;
    background: #74C6C7;
}

.category-detail-header > div:nth-child(1),
.product-detail-header > div:nth-child(1) {
    position: relative;
    z-index: 2;
    overflow: hidden;
}

.category-detail-header > div:nth-child(1)::before,
.product-detail-header > div:nth-child(1)::before {
    content: "";
    background: url('/images/Frame7.png') no-repeat;
    position: absolute;
    top: calc(50% + 20px);
    left: 100%;
    -webkit-transform: translate(-100%, -50%);
    -moz-transform: translate(-100%, -50%);
    -ms-transform: translate(-100%, -50%);
    -o-transform: translate(-100%, -50%);
    transform: translate(-100%, -50%);
    z-index: -1;
    width: 100%;
    height: 100%;
    max-width: 610px;
    max-height: 128px;
}

.category-detail-header > div:nth-child(1) > div:nth-child(1),
.product-detail-header > div:nth-child(1) > div:nth-child(1){
    padding-top: 40px;
    padding-bottom: 20px;
}

.category-detail-header .categorie-top-usps, .product-top-usps {
    background: #2B2B2B;
}

.category-detail-header .categorie-top-usps * {

}

.category-detail-main .catprod .image-container {
    border: 1px solid #C4C4C4;
    box-sizing: border-box;
}

.category-detail-main .catprod .product-info {
    background: #2B2B2B;
    margin-left: 0px;
    margin-right: 0px;
    padding: 15px 15px !important;
    height: 113px;
}

.category-detail-main .catprod .product-info .product-title {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 29px;
    color: #FFFFFF;
    text-align: left;
}

.category-detail-main .catprod .product-info .product-price {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 29px;
    color: #FFFFFF;
    text-align: left;
}

.category-detail-main .catprod .product-info .col-10 {
    padding-left: 0px !important;
}

/*.category-detail-main .catprod .product-info .col-2 img {
    width: 30px;
    height: 30px;
    transform: scale(1.4);
    -webkit-transform: scale(1.4);
}
*/
.cartnav ul {
    margin-bottom: 0px;
}

.product-detail-header {
    /*background: url('/images/bg.jpg') no-repeat 50% 50%;*/
    /*background: url('/images/fabric-textured-background.jpg') no-repeat 50% 50%;*/
    background-size: cover;
    padding-top: 0px;
    padding-bottom: 0px;
    background: #74C6C7;
}

.category-detail-main .category-filter-item + .category-filter-item {
    position: relative;
    z-index: 0;
}

.category-detail-main .category-filter-item + .category-filter-item::before {
    content: '';
    display: block;
    position: absolute;
    left: 15px;
    z-index: -1;
    width: calc(100% - 30px);
    border-top: 1px solid rgba(116, 198, 199, 0.5);
}

.category-detail-main .category-filters-container {
    /*background: #f6f0ea;*/
    padding: 60px 50px 60px 0px;
    box-sizing: border-box;
}

.category-detail-main .category-filters-container h4 {
    /*border-bottom: 1px solid rgba(18, 14, 14, 0.2);*/
    padding-bottom: 10px;
}

.category-detail-main .category-filters-container h4,
.category-detail-main .category-filters-container h4 * {
    /*font-family: $font5;
    font-weight: normal;
    font-style: normal;
    color: $brown-v1;
    font-size: 18px;
    text-transform: uppercase;*/
    font-family: 'Roboto';
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 200%;
    color: #1E1E20;
    text-transform: none;
}

.category-detail-main .category-filters-container h4 > div > div {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-align-content: center;
    align-content: center;
    -webkit-align-items: center;
    align-items: center;
    justify-content: space-between;
    -webkit-justify-content: space-between;
}

.category-detail-main .category-filters-container h4 .fal {
    font-family: "Font Awesome 5 Pro";
    font-weight: 300;
    color: #272727;
    font-size: 18px;
}

.filter-mob .fal {
    font-size: 18px;
}

.filter-mob h4 {
    font-family: "Montserrat-Light", sans-serif;
    color: #a26c35;
    font-size: 18px;
    text-transform: uppercase;
}

.filter-mob h4 .fal {
    color: #272727;
}

.filter-mob h4 div {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-align-content: center;
    align-content: center;
    -webkit-align-items: center;
    align-items: center;
    justify-content: space-between;
    -webkit-justify-content: space-between;
}

.category-detail-header a, .product-detail-header a {
    font-family: $font1;
    /*color: $brown-v2;*/
    color: #FFF;
}

.category-detail-header a:hover,
.category-detail-header a:focus,
.product-detail-header a:hover,
.product-detail-header a:focus {
    /*color: $brown-v1;**/
    color: #2b2b2b;
    text-decoration: none;
}

.product-detail-header .align-items-center > div {
    position: relative;
    z-index: 10;
}

.category-detail-main > div > div.col-xl-9 {
    padding-top: 60px;
}

.category-detail-main .category-filters-container .category-filter-item > div {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-align-content: center;
    align-content: center;
    -webkit-align-items: center;
    align-items: center;
    justify-content: space-between;
    -webkit-justify-content: space-between;
    padding-top: 10px;
    padding-bottom: 10px;
}

.category-detail-main .category-filters-container .category-filter-item a {
    font-family: $font1;
    color: $darkgrey-v1;
    font-size: 18px;
}

.category-detail-main .category-filters-container .category-filter-item ul {
    list-style: none;
    padding-left: 0px;
}

/*.category-detail-main .category-filters-container .category-filter-item .sub-cat::marker {
        display: none;
    }*/

.category-detail-main .category-filters-container .category-filter-item .sub-cat a {
    display: inline-block;
}


#description-long-link {
    display: block;
    position: relative;
    top: -90px;
    visibility: hidden;
}

#brand-filter {
    color: $darkgrey-v1;
    font-family: $font1;
    font-size: 18px;
}

.header-home-icons {
    position: relative;
    z-index: 999;
}


.catprod {
    position: relative;
}

.catprod .lebel {
    position: absolute;
    top: 1px;
    /*left: 12px;*/
    left: 1px;
    background-color: #74C6C7;
    color: #FFF;
    box-sizing: border-box;
    padding: 5px 10px;
    font-family: $font2;
}


.over-ons-header > div > div > div,
.contact-header > div > div > div,
.algemene-voorwaarden-header > div > div > div,
.disclaimer-header > div > div > div,
.privacy-policy-header > div > div > div {
    position: relative;
    z-index: 0;
}

/*.over-ons-header > div > div > div > div:nth-child(1),
.contact-header > div > div > div > div:nth-child(1),
.algemene-voorwaarden-header > div > div > div > div:nth-child(1),
.disclaimer-header > div > div > div > div:nth-child(1),
.privacy-policy-header > div > div > div > div:nth-child(1) {
    z-index: 1;
    position: absolute;
    top: 50%;
    left: 100px;
    -webkit-transform: translate(0%, -50%);
    -moz-transform: translate(0%, -50%);
    -ms-transform: translate(0%, -50%);
    -o-transform: translate(0%, -50%);
    transform: translate(0%, -50%);
}*/

.over-ons-header h1,
.contact-header h1,
.algemene-voorwaarden-header h1,
.disclaimer-header h1,
.privacy-policy-header h1 {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: bold;
    font-size: 48px;
    line-height: 52px;
    color: #FFFFFF;
    max-width: 800px;
    text-transform: none;
    margin-bottom: 0px;
}

.over-ons-header > div,
.contact-header > div,
.algemene-voorwaarden-header > div,
.disclaimer-header > div,
.privacy-policy-header > div {
    padding-top: 40px;
    padding-bottom: 20px;
    max-height: 128px;
}

.over-ons-header,
.contact-header,
.algemene-voorwaarden-header,
.disclaimer-header,
.privacy-policy-header {
    position: relative;
    z-index: 0;
    overflow: hidden;
}

.over-ons-header::before,
.contact-header::before,
.algemene-voorwaarden-header::before,
.disclaimer-header::before,
.privacy-policy-header::before,
.checkout-title-container::before {
    content: '';
    background: url('/images/Frame7.png') no-repeat;
    position: absolute;
    top: 50%;
    left: 100%;
    -webkit-transform: translate(-100%, -50%);
    -moz-transform: translate(-100%, -50%);
    -ms-transform: translate(-100%, -50%);
    -o-transform: translate(-100%, -50%);
    transform: translate(-100%, -50%);
    z-index:-1;
    width: 100%;
    height: 100%;
    max-width: 610px;
    max-height: 128px;
}

.over-ons-usps {
    background: #2B2B2B;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 24px;
    color: #FFFFFF;
}


.over-ons-rust {
    /*background: url('/images/bg.jpg') no-repeat 50% 50%;*/
    background: url('/images/fabric-textured-background.jpg') no-repeat 50% 50%;
    background-size: cover;
    padding-top: 180px;
    padding-bottom: 70px;
}

.over-ons-rust h2 {
    max-width: 320px;
}

.over-ons-rust p {
    max-width: 460px;
}

/*
.over-ons-rust-col2 {
    position: relative;
}

.over-ons-rust-col2 img {
    position: absolute;
    top: 66%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    max-height: 662px;
    max-width: 557px;
    object-fit: cover;
}*/

.over-ons-uniek-verdeler > div > div {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-align-content: center;
    align-content: center;
    -webkit-align-items: center;
    align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
}

.over-ons-kwaliteit {
    padding-top: 200px;
    padding-bottom: 140px;
}

.over-ons-kwaliteit > div > div {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-align-content: center;
    align-content: center;
    -webkit-align-items: center;
    align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
}

.over-ons-kwaliteit > div > div > div:nth-child(2) {

    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-align-content: center;
    align-content: center;
    -webkit-align-items: center;
    align-items: center;
    justify-content: center;
    -webkit-justify-content: center;

}

.over-ons-kwaliteit img {
    max-height: 490px;
    max-width: 467px;
    object-fit: cover;
}

.over-ons-kwaliteit h2 {
    max-width: 340px;
}

.over-ons-kwaliteit p {
    color: $darkgrey-v2;
    max-width: 470px;
}

.over-ons-uniek-verdeler > div {
    padding-top: 90px;
    padding-bottom: 90px;
}

.over-ons-slider-section {
    background: url('/images/about-us-slider-bg.jpg') no-repeat 50% 50%;
    background-size: cover;
    padding-top: 90px;
    padding-bottom: 90px;
}

.over-ons-slider-section .uk-slidenav {
    color: $brown-v1;
}

.over-ons-slider-section .uk-slidenav:hover,
.over-ons-slider-section .uk-slidenav:focus {
    color: $brown-v2;
}

.over-ons-slider-section .uk-slider-items img {
    height: 280px;
    max-height: 280px;
    width: 100%;
    object-fit: cover;
}

.over-ons-contact {
    /*background: url('/images/bg.jpg') no-repeat 50% 50%;*/
    background: url('/images/fabric-textured-background.jpg') no-repeat 50% 50%;
    background-size: cover;
    padding-top: 150px;
    padding-bottom: 140px
}

.over-ons-contact p {
    max-width: 420px;
}

.over-ons-contact a {
    display: inline-block;
    background-color: #a26c35;
    color: #fff;
    font-family: "Montserrat-Light", sans-serif;
    font-size: 17px;
    padding: 7px 15px;
    text-transform: uppercase;
    transition: all 0.25s ease;
    -webkit-transition: all 0.25s ease;
    border-radius: 0px;
}

.over-ons-contact a:hover,
.over-ons-contact a:focus {
    background-color: #ccb59e;
    text-decoration: none;
}

.over-ons-contact-col2 {
    position: relative;
    z-index: 1;
}

.over-ons-contact-col2 img {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    max-height: 674px;
    max-width: 577px;
    object-fit: cover;
}

.over-ons-contact > div > div > div:nth-child(1) {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-align-content: center;
    align-content: center;
    -webkit-align-items: center;
    align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
}

.contact-main form input:not([type=checkbox]):not([type=radio]) {
    height: 40px !important;
}

.contact-main form input:not([type=checkbox]):not([type=radio]),
.contact-main form textarea {
    font-size: 18px !important;
}

.contact-main .privacy-notice-paragraph {
    font-size: 12px;
    line-height: 18px;
}

.contact-main .privacy-notice-paragraph a {
    color: $brown-v2;
}

.contact-main .privacy-notice-paragraph a:hover,
.contact-main .privacy-notice-paragraph a:focus {
    color: $brown-v1;
    text-decoration: none;
}

.contact-main input[type=checkbox] {
    border: 1px solid $brown-v1;
}

.contact-main textarea {
    /*border: 1px solid $brown-v1 !important;*/
}

.contact-main label::before {
    display: none !important;
}

.contact-main button {
    display: inline-block;
    background-color: $brown-v1;
    border: 1px solid $brown-v1;
    color: #fff;
    font-family: "Montserrat-Light", sans-serif;
    font-size: 17px;
    padding: 0 28px;
    text-transform: uppercase;
    transition: all 0.25s ease;
    -webkit-transition: all 0.25s ease;
    margin-top: 20px;
}

.contact-main .upload-button button {
    margin-top: 0px;
}

.contact-main button:hover,
.contact-main button:focus {
    background-color: $brown-v2;
    border: 1px solid $brown-v2;
    text-decoration: none;
}

.contact-main > div > div > div:nth-child(2) a {
    color: $main-color;
}

.contact-main > div > div > div:nth-child(2) a:hover,
.contact-main > div > div > div:nth-child(2) a:focus {
    color: $black;
}

.contactmap {
    margin-top: 40px;
}

.contact-main #form-group-checkbox-group-1635514227957 label {
    font-size: 18px;
    line-height: 24px;
}

.site-footer {
    /*background: url('/images/footer-bg.jpg') no-repeat 50% 50%;*/
    /*background: url('/images/fabric-textured-background.jpg') no-repeat 50% 50%;
    background-size: cover;*/
    background: $black;
    padding-top: 70px;
    padding-bottom: 70px;
}

.site-footer * , .site-footer a {
    font-family: $font1;
    font-style: normal;
    font-weight: 300;
    font-size: 20px;
    line-height: 30px;
    color: $white;
}

.site-footer a:hover, .site-footer a:focus {
    color: rgba(255, 255, 255, 0.8);
    text-decoration: none;
}

.site-footer h3 {
    font-family: $font1;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 30px;
    margin-bottom: 0px;
    text-transform: none;
}
/*
.footer-col2 {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-align-content: center;
    align-content: center;
    -webkit-align-items: center;
    align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
}

.footer-col3 {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-align-content: center;
    align-content: center;
    -webkit-align-items: center;
    align-items: center;
    justify-content: flex-end;
    -webkit-justify-content: flex-end;
}*/

.footer-col4 {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-align-content: center;
    align-content: center;
    -webkit-align-items: center;
    align-items: center;
    justify-content: flex-end;
    -webkit-justify-content: flex-end;
    text-align: right;
}

.searchpage-content > div {
    padding-top: 70px;
    padding-bottom: 70px;
}

.searchpage-content .search-field {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-align-content: center;
    align-content: center;
    -webkit-align-items: center;
    align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
}

.searchpage-content .search-field-input,
.searchpage-content .do-search-button {
    display: inline-block;
}

.searchpage-content .search-field-input input {min-width: 300px;padding-left: 10px;padding-right: 10px;padding-top: 8px;padding-bottom: 8px;}

.searchpage-content .do-search-button {
    background: $brown-v1;
    color: $white;
    padding: 10px 20px;
    box-sizing: border-box;
    height: 48px;
    /*line-height: 36px;*/
    line-height: 30px;
    cursor: pointer;
}

.searchpage-content .do-search-button .far {
    position: relative;
    top: 2px;
    left: 2px;
}

.searchpage-content .do-search-button:hover,
.searchpage-content .do-search-button:focus {
    /*background: $brown-v2;*/
    background: #2b2b2b;
}


.uk-offcanvas-bar {
    /*background: url(/images/bg.jpg) no-repeat 50% 50%;*/
    /*background: url('/images/fabric-textured-background.jpg') no-repeat 50% 50%;*/
    background: #FFF;
    background-size: cover;
}

.uk-offcanvas-bar .menu-main-menu li {
    display: block;
    width: 100%;
}

.offcanvas-logo {
    margin-top: 100px;
    margin-bottom: 40px;
    display: inline-block;
}

.uk-offcanvas-bar .menu-main-menu {
    height: auto !important;
}

.uk-offcanvas-bar .menu-main-menu li a {
    font-family: "Montserrat-Bold", sans-serif;
    font-size: 22px;
    line-height: 38px;
    color: #a26c35 !important;
    text-transform: uppercase;
}

.uk-offcanvas-bar .menu-main-menu li a:hover,
.uk-offcanvas-bar .menu-main-menu li a:focus {
    color: $brown-v2 !important;
    text-decoration: none;
}

.uk-offcanvas-close {
    /*color: #FFF;*/
    color: $brown-v2;
}

.uk-offcanvas-close:hover,
.uk-offcanvas-close:focus {
    color: $brown-v1;
}

.uk-offcanvas-close svg {
    width: 26px;
    height: 26px;
}


ul.menu-main-menu > li > a:after, ul.menu-main-menu > li > a:visited:after, ul.menu-main-menu > li > span:after, ul.menu-language-items > li > a:after, ul.menu-language-items > li > a:visited:after, ul.menu-language-items > li > span:after {
    display: none;
}

.form-steps li {
    border: none;
    transform: none;
}


.form-steps {
    padding-left: 0px;
}


.form-steps li.enabled, .form-steps li.current {
    border: none;
    background: none;
}

.form-steps li svg.fa-check-circle, .form-steps li svg.fa-circle,
.form-steps li i.fa-check-circle, .form-steps li i.fa-circle {
    margin-right: 8px;
    position: relative;
    top: 5px;
    width: 27px !important;
    height: 27px;
    color: $brown-v2;
    font-size: 27px;
}

.form-steps svg.fa-horizontal-rule, .form-steps i.fa-horizontal-rule {
    max-width: 14px;
    margin-left: 6px;
    /*margin-right: 6px;*/
    margin-right: 15px;
}

.form-steps li a, .form-steps li span {
    transform: none;
    padding: 10px 0px;
    color: #000;
}

.cart-content-section {
    padding-top: 70px;
    padding-bottom: 70px;
}


.order-btn, .proceed-button a {
    background-color: $brown-v1;
}

.order-btn:hover, .proceed-button a:hover,
.order-btn:focus, .proceed-button a:focus {
    /*background-color: $brown-v2;*/
    background-color: #2B2B2B;

}

.checkout-page {
    .invalid-feedback {
        display:block !important;
        list-style: none;
        padding-left: 0;
    }
}

.checkout-page .butn-next {
    display: inline-block;
    background-color: $brown-v1;
    color: #fff;
    font-family: $font1;
    font-size: 20px;
    padding: 10px 28px;
    /*text-transform: uppercase;*/
    transition: all 0.25s ease;
    -webkit-transition: all 0.25s ease;
    height: 45px;
    line-height: 25px;
}

.checkout-page .butn-next:hover,
.checkout-page .butn-next:focus {
    background-color: #2B2B2B;
    text-decoration: none;
}


.checkout-page .butn-light {
    display: inline-block;
    background-color: #F3F3F3;
    /*color: #fff;
    font-family: $font1;
    font-size: 17px;
    padding: 10px 28px;*/
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    font-size: 20px;
    /*line-height: 24px;*/
    /* identical to box height */
    color: #0A0A0A;
    padding: 10px 28px;
    /*text-transform: uppercase;*/
    transition: all 0.25s ease;
    -webkit-transition: all 0.25s ease;
    height: 45px;
    line-height: 25px;
}

.checkout-page .butn-light:hover,
.checkout-page .butn-light:focus {
    background-color: $brown-v1;
    color: #FFF;
    text-decoration: none;
}

.login-existing-customer a {
    color: $brown-v1;
}

.login-existing-customer a:hover,
.login-existing-customer a:focus {
    /*color: $brown-v2;*/
    color: #2B2B2B;
    text-decoration: none;
}

#login-form-modal .btn {
    display: inline-block;
    background-color: $brown-v1;
    color: #fff;
    font-family: $font1;
    font-size: 17px;
    padding: 10px 28px;
    text-transform: uppercase;
    transition: all 0.25s ease;
    -webkit-transition: all 0.25s ease;
}

#login-form-modal .btn:hover,
#login-form-modal .btn:focus {
    background-color: $brown-v2;
}

.form-control, .select2-container .selection .select2-selection {
    border-radius: 0px;
}

.select2-container .selection .select2-selection {
    border-radius: 0px !important;
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
    border-radius: 0px !important;
}

.cart-item-block {
    border: 1px solid #ccc;
    padding: 10px;
    /*border-radius: 6px;*/
    border-radius: 0px;
}

.cart-item-block .image-container img {
    /*border-radius: 6px;*/
    border-radius: 0px;
}

.cart-content-row + .cart-content-row {
    border-top: none;
    margin-top: 30px;
}

.filter-mob {
    padding-left: 15px;
    padding-right: 15px;
}

.filter-mob a {
    font-family: "Montserrat-Light", sans-serif;
    color: #27211e;
    font-size: 18px;
}

.filter-mob .category-filter-item > div {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-align-content: center;
    align-content: center;
    -webkit-align-items: center;
    align-items: center;
    justify-content: space-between;
    -webkit-justify-content: space-between;
}

.filter-mob .subcategories-list {
    list-style: none;
    padding-left: 0px;
}

.filter-mob .subcategories-list li a {
    display: inline-block;
}

.mini-cart .badge-main {
    /*background-color: $brown-v1;*/
    background: #2b2b2b;
    color: $white;
    position: absolute;
    right: -15px;
    top: -20px;
    border-radius: 34px;
}


.size-box input + label {
    padding: 5px 10px;
}

.size-box input:checked + label {
    background-color: $brown-v1;
    color: $white;
}

.form-control:focus, .select2-container .selection .select2-selection:focus {
    box-shadow: none;
}

.select2-container .selection .select2-selection {
    /*border: 1px solid #a26c35;*/
    border: 1px solid #c4c4c4;
    border-radius: 0.25rem;
    height: 45px;
}

.voorwaarden-link {color: $brown-v1;}
.voorwaarden-link:hover,
.voorwaarden-link:focus {/*color: $brown-v2;*/
    color: #2B2B2B;
    text-decoration: none;
}

.form-control:focus, .select2-container .selection .select2-selection:focus {
    border: 1px solid #a26c35;
    /*border-color: #a26c35;*/
    border: 1px solid #C4C4C4;
    border-color: #C4C4C4;
}

.home-brands .row {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-align-content: center;
    align-content: center;
    -webkit-align-items: center;
    align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
}

.home-brands .row img {
    max-height: 120px;
    object-fit: contain;
}


.algemene-voorwaarden-main a {
    color: #ccb59e;
}

.algemene-voorwaarden-main a:hover,
.algemene-voorwaarden-main a:focus {
    color: #a26c35;
    text-decoration: none;
}

.algemene-voorwaarden-main p,
.algemene-voorwaarden-main li {
    color: #27201c;
}

.algemene-voorwaarden-main h3 {
    font-family: $font5;
    font-weight: normal;
    font-style: normal;
    color: $brown-v1;
    font-size: 20px;
    text-transform: uppercase;
    letter-spacing: 2px;
    text-align: left;
}

.algemene-voorwaarden-main #art1,
.algemene-voorwaarden-main #art2,
.algemene-voorwaarden-main #art3,
.algemene-voorwaarden-main #art4,
.algemene-voorwaarden-main #art5,
.algemene-voorwaarden-main #art6,
.algemene-voorwaarden-main #art7,
.algemene-voorwaarden-main #art8,
.algemene-voorwaarden-main #art9,
.algemene-voorwaarden-main #art10,
.algemene-voorwaarden-main #art11,
.algemene-voorwaarden-main #art12,
.algemene-voorwaarden-main #art13,
.algemene-voorwaarden-main #art14,
.algemene-voorwaarden-main #art15,
.algemene-voorwaarden-main #art16 {
    display: block;
    position: relative;
    top: -160px;
    visibility: hidden;
}


.product_option_remarks {
    display: none;
}

.menuwebshop {margin-left: 30px;}

/*.webshop-categories > div.container {margin-bottom: 20px;}
.webshop-categories > div.container {
    padding-left: 0px;
    padding-right: 0px;
}
.webshop-categories > div.container > div.row {
    margin-left: -10px;
    margin-right: -10px;
}

.webshop-categories > div.container > div.row > div {
    padding-left: 10px;
    padding-right: 10px;
}

.webshop-categories > div.container > div.row > div > div.row {
    margin-left: -10px;
    margin-right: -10px;
}*/



/*@media (min-width: 960px) and (max-width: 1199px) {
        .over-ons-slider-section .uk-slidenav {
            background: #FFF;
            color: #a26c35;
        }

        .home-product-slider-nav {
            display: block !important;
        }
    }*/

input, select, textarea {-webkit-appearance: none;}

.price-input:before {
    top: 2px;
}

.cart-page .qty-selector .cart-qty {
    height: 35px;
    border: none;
    width: auto;
    text-align: left !important;
}

.toggle-filter {
    border-radius: 0px !important;
}

.cart-qty-selector {
    position: relative;
    display: inline-block;
    border: 1px solid $brown-v1;
    padding: 0px 5px;
    box-sizing: border-box;
}

.qty-selector .cart-qty-select-open {
    height: auto;
    width: auto;
    background-color: transparent;
    border: none;
    color: #fff;
    text-align: center;
    margin-left: 0px;
    padding: 0px 0;
    cursor: pointer;
}

.qty-selector .cart-qty-select-options {
    left: -1px;
}


.cart-page .add-discount-code,
.checkout-page .add-discount-code {
    display: inline-block;
    background-color: #74C6C7;
    color: #fff;
    font-family: "Montserrat-Light", sans-serif;
    font-size: 17px;
    padding: 10px 28px;
    text-transform: uppercase;
    transition: all 0.25s ease;
    -webkit-transition: all 0.25s ease;
}

.cart-page .add-discount-code:hover,
.cart-page .add-discount-code:focus,
.checkout-page .add-discount-code:hover,
.checkout-page .add-discount-code:focus {
    background-color: #2B2B2B;
    text-decoration: none;
}

.checkout-title-container {
    max-height: 128px;
}


.price-input:before {
    color: #666 !important;
    top: 5px;
}


@media (min-width: 992px) and (max-width: 1440px) {
    .checkout-page .cart-totals-html-desktop {
        margin-left: 2% !important;
        flex: 0 0 30%;
        max-width: 30%;
    }
}

@media (min-width: 992px) and (max-width: 1680px) {
    .uk-position-center-left-out {right:calc(100% - 60px);}
    .uk-position-center-right-out {left:calc(100% - 60px);}
}

@media (max-width: 1680px) {
    .algemene-voorwaarden-header > div, .disclaimer-header > div, .privacy-policy-header > div, .contact-header > div {
        padding-left: 15px;
        padding-right: 15px;
    }

    .checkout-title-container > div {
        padding-left: 15px;
        padding-right: 15px;
    }

}


@media (min-width: 992px) and (max-width: 1199px) {

    .home-usps ul {
        padding-left: 12px;
        padding-right: 12px;
    }

    .home-usps ul li {
        padding-right: 12px;
    }

    .home-ontspannen-genieten-row1-col2 img {max-width: 457px;}
    .home-ontspannen-genieten-row2-col1 img {max-width: 457px;}
    /* .home-verpakken-col2 img {max-width: 457px;}*/
    .over-ons-rust-col2 img {max-width: 457px;}
    .over-ons-contact-col2 img {max-width: 457px;}
    .over-ons-slider-section {
        padding-bottom: 150px;
    }

    .home-categories-section {padding-bottom: 100px !important;}

    .home-ontspannen-genieten-row1 {
        padding-bottom: 100px;
    }
    .home-ontspannen-genieten-row2 {
        padding-top: 100px;
    }

}

@media (min-width: 768px) and (max-width: 991px) {
    .home-ontspannen-genieten-row1-col2 img {max-width: 357px;}
    .home-ontspannen-genieten-row2-col1 img {max-width: 357px;}
    /*.home-verpakken-col2 img {max-width: 357px;}*/
    .over-ons-rust-col2 img {max-width: 357px;}
    .over-ons-kwaliteit img {max-width: 367px;}
    .over-ons-contact-col2 img {max-width: 357px;}
    .over-ons-slider-section {
        padding-bottom: 140px !important;
    }
    .home-categories-section {padding-bottom: 100px !important;}
}

@media (min-width: 768px) {

    .home-main > div > div > div:nth-child(1) {
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-wrap: wrap;
        flex-wrap: wrap;
        -webkit-align-content: center;
        align-content: center;
        -webkit-align-items: center;
        align-items: center;
        justify-content: flex-start;
        -webkit-justify-content: flex-start;
    }

    .home-bottom > div > div > div:nth-child(2) {
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-wrap: wrap;
        flex-wrap: wrap;
        -webkit-align-content: center;
        align-content: center;
        -webkit-align-items: center;
        align-items: center;
        justify-content: flex-end;
        -webkit-justify-content: flex-end;
    }

    .home-bottom > div > div > div:nth-child(2) img {
        max-width: 552px;
        object-fit: contain;
    }




    .collapse.dont-collapse-sm {
        display: block;
        height: auto !important;
        visibility: visible;
    }

    .contact-main > div > div > div:nth-child(1) {padding-right: 20px;}
    .contact-main > div > div > div:nth-child(2) {padding-left: 20px;}

    /*.productgalleryslider {
        flex: 0 0 17.15%;
        max-width: 17.15%;
    }
    .productgallerymainimg {
        flex: 0 0 82.85%;
        max-width: 82.85%;
    }*/

    #product-detail > div > div > div:nth-child(1) {padding-right: 20px;}

    #product-detail > div > div > div:nth-child(2) {padding-left: 20px;}

    .catprod {
        padding-left: 12px !important;
        padding-right: 12px !important;
    }

}


@media (min-width: 992px) {

    .home-warmte-in-de-huiskamer-row2 {
        padding-top: 80px;
    }

    .cart-page .cart-page-cart-content-totals {
        margin-left: 4%;
    }

    .checkout-page .cart-totals-html-desktop {
        margin-left: 4%;
    }

    .cart-totals-html-mobile {
        display: none;
    }

    .searchpage-content {
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-wrap: wrap;
        flex-wrap: wrap;
        -webkit-align-content: center;
        align-content: center;
        -webkit-align-items: center;
        align-items: center;
        justify-content: center;
        -webkit-justify-content: center;
        min-height: 750px;
    }

    .contact-main > div > div > div:nth-child(1) {padding-right: 40px;}
    .contact-main > div > div > div:nth-child(2) {padding-left: 40px;}

    .privacyverklaring-main-content-table-mobile {
        display: none;
    }
}



@media (min-width: 1200px) {

    .over-ons-uniek-verdeler .over-ons-rust-col1 * {max-width: 615px;}
    .over-ons-uniek-verdeler .over-ons-rust-col2 {text-align: right;}
    .over-ons-uniek-verdeler .over-ons-rust-col2 img {max-width: 555px;}

    .site-footer .col-lg-4 {
        flex: 0 0 30.3333333333%;
        max-width: 30.3333333333%;
    }

    .site-footer .col-lg-2 {
        flex: 0 0 19.6666666667%;
        max-width: 19.6666666667%;
    }

    /*.container {
        max-width: 1470px;
    }*/
    .container {
        max-width: 1280px;
    }

    .category-detail-main, .checkout-page-form {
        max-width: 1280px;
    }



    .pager {margin-top: 60px;}

    .info-header > div {
        padding-left: 30px !important;
        padding-right: 30px !important;
    }

    /*.category-detail-header > div {
        padding-left: 262px;
    }*/

    .category-detail-header > div:nth-child(1) > div:nth-child(1), .category-detail-header > div:nth-child(1) > div:nth-child(1) {
        /*padding-left: 262px;*/
        max-width: 1280px;
        margin-left: auto;
        margin-right: auto;
    }

    .cartnav  > div {
        /*padding-left: 262px;*/
        max-width: 1280px;
        margin-left: auto;
        margin-right: auto;
    }

    .checkout-title-container > div {
        max-width: 1280px;
        padding-left: 0px;
        margin-left: auto;
        margin-right: auto;
    }

    .product-detail-header > div > div {
        /*padding-left: 262px;*/
        max-width: 1280px;
        margin-left: auto;
        margin-right: auto;
    }

    .category-detail-main > div > div.col-xl-3 {
        flex: 0 0 21.94%;
        max-width: 21.94%;
    }

    .site-footer > .container {
        max-width: 1360px;
    }


}

/*
.product-detail-header > div > div {
    max-width: 1280px;
    margin-left: auto;
    margin-right: auto;
}
*/


@media (min-width: 992px) {

    .home-unieke-collectie-homewear img {
        height: 662px;
        /*max-width: 557px;*/
        object-position: 43% 50%;
    }

    .home-categories img {
        min-height: 346px;
    }

    .home-ontspannen-genieten-row1-col2 img {
        height: 674px;
    }

    .home-ontspannen-genieten-row2-col1 img {
        height: 577px;
        object-position: 66% 50%;
    }

    .home-warmte-in-de-huiskamer-row1 img {
        height: 396px;
    }

    .home-warmte-in-de-huiskamer-row2 img {
        height: 490px;
    }

    .home-verpakken-col2 img {
        height: 674px;
    }

    /*.over-ons-rust-col2 img {
        height: 662px;
    }*/

    .over-ons-rust-col2 img {
        height: 500px;
    }

    .over-ons-kwaliteit img {
        height: 490px;
    }

    .over-ons-contact-col2 img {
        height: 674px;
    }

}

@media (max-width: 1800px) {

    .pl-5, .px-5 {
        padding-left: 40px !important;
    }

    .pr-5, .px-5 {
        padding-right: 40px !important;
    }

    .pl-md-5, .px-md-5 {
        padding-left: 40px !important;
    }

    .pr-md-5, .px-md-5 {
        padding-right: 40px !important;
    }

}

@media (max-width: 1439px) {

    .container {
        padding-left: 25px;
        padding-right: 25px;
    }



    .pl-5, .px-5 {
        padding-left: 40px !important;
    }

    .pr-5, .px-5 {
        padding-right: 40px !important;
    }

    .pl-md-5, .px-md-5 {
        padding-left: 40px !important;
    }

    .pr-md-5, .px-md-5 {
        padding-right: 40px !important;
    }


    .home-usps ul {
        margin-right: -40px;
    }

    .home-usps ul li {
        padding-right: 40px;
        font-size: 15px;
    }

    .over-ons-header > div > div > div > div:nth-child(1), .contact-header > div > div > div > div:nth-child(1), .algemene-voorwaarden-header > div > div > div > div:nth-child(1), .disclaimer-header > div > div > div > div:nth-child(1), .privacy-policy-header > div > div > div > div:nth-child(1) {
        left: 40px;
    }

}

@media (max-width: 1199px) {

/*    .home-header-logo + .menu-container {
        display: none;
    }
*/

    .mobile-menu-block {
        opacity: 0;
        position: fixed;
        top: 0;
        left: 0;
        height: 100vh;
        width: 75vw;
        background: #fff;
        pointer-events: none;
        transform: translateX(-100%);
        opacity: 0;
        transition: all 300ms ease;
    }

    .mobile-menu-block.open {
        z-index: 9999;
        opacity: 1;
        transform: translateX(0);
        pointer-events: all;
    }

/*    .menu-main-menu {
        display: none !important;
    }*/

    .pl-5, .px-5 {
        padding-left: 20px !important;
    }

    .pr-5, .px-5 {
        padding-right: 20px !important;
    }

    .pl-md-5, .px-md-5 {
        padding-left: 20px !important;
    }

    .pr-md-5, .px-md-5 {
        padding-right: 20px !important;
    }

    .product-detail-header {
        padding-top: 0px;
        padding-left: 15px;
        padding-right: 15px;
    }

    .alert + .product-detail-header {
        padding-top: 20px;
    }

    .mini-cart .badge-main {
        /*background-color: $brown-v1;*/
        background-color: #2b2b2b;
        color: $white;
        position: absolute;
        right: -2px;
        top: -14px;
    }


    header > div.px-5 {
        padding-left: 15px !important;
        padding-right: 15px !important;
    }

    .info-header .search-field {
        margin-bottom: 0px;
    }



    .home-usps ul {
        /*margin-right: -20px;*/
        margin-right: 0px;
    }

    /*.home-usps ul li {
    padding-right: 20px;
}*/

    .home-usps ul li {
        padding-right: 8px;
    }

    .container {
        max-width: 100%;
    }
}



@media (min-width: 768px) and (max-width: 991px) {
    .cart-page-cart-content .cart-page-cart-content-totals {
        flex: 0 0 50%;
        max-width: 50%;
        margin-left: auto;
        margin-right: 15px;
        margin-top: 40px;
    }

    .cart-page-cart-content .cart-page-cart-content-totals > div:nth-child(1) > div {
        margin-left: 0px;
        margin-right: 0px;
    }

}

.webshop-categories-container > div > div {
    padding-left: 10px;
    padding-right: 10px;
}

@media (max-width: 991px) {

    .category-detail-header h1, .product-detail-header h1, .checkout-title-container h1 {
        font-size: 36px;
        line-height: 40px;
    }

    .home-usps ul li, .over-ons-usps ul li, .categorie-top-usps ul li, .product-top-usps ul li,
    .category-detail-main .catprod .product-info .product-title, .full-offer {
        font-size: 18px;
    }

    .home-header p, .home-categories ul li, .site-footer *, .site-footer a {
        font-size: 18px;
        line-height: 28px;
    }


    .home-header > div {
        padding-top: 75px;
        padding-bottom: 60px;
    }

    .home-main > div, .home-popular-products > div, .home-logos > div, .site-footer, .over-ons-uniek-verdeler > div {
        padding-top: 40px;
        padding-bottom: 40px;
    }

    .over-ons-slider-section {
        padding-top: 40px;
    }

    .site-footer {
        padding-top: 60px;
    }

    .home-main > div, .home-podium-op-maat {
        padding-bottom: 20px;
    }

    .home-bottom > div {
        padding-top: 0px;
    }

    .home-usps ul {
        margin-bottom: 20px !important;
    }

    .footer-col1 {
        text-align: center !important;
        margin-bottom: 40px;
    }

    .footer-col2 {
        margin-top: 0 !important;
    }

    .menuwebshop {
        margin-left: 15px;
    }

    .header-icons .menutext {
        font-size: 17px;
    }


    .webshop-categories-container {
        padding-left: 0px;
        padding-right: 0px;
    }
    .webshop-categories-container > div > div {
        padding-left: 10px;
        padding-right: 10px;
    }

    .home-merken {
        padding-top: 40px;
        padding-bottom: 40px;
    }

    .home-categories-title + .container {
        margin-top: -20px;
    }

    #related-products h2 {
        max-width: none;
    }

    .back-to-webshop {
        padding-top: 0px;
        margin-top: 0px !important;
        padding-bottom: 20px !important;
        margin-bottom: 20px !important;
    }

    #related-products h2 {
        font-size: 24px;
    }

    #related-products .related-products > div > div {
        margin-bottom: 15px !important;
    }

    #related-products .product-list-item .product-info {
        padding-bottom: 15px !important;
    }

    #properties-description .description h2 {
        font-size: 32px;
    }

    #properties-description {
        padding-top: 40px;
        padding-bottom: 40px;
    }

    .home-warmte-in-de-huiskamer-row2 {
        padding-top: 40px;
    }

    .qty-add-to-cart > div {
        width: 100%;
    }

    #product-detail .order-btn, #product-detail .proceed-button a, .proceed-button #product-detail a {
        padding: 0px 20px;
    }

    #product-detail .qty-add-to-cart .col-5,
    #product-detail .qty-add-to-cart .col-7 {
        flex: 0 0 50%;
        max-width: 50%;
    }

    #product-detail .qty-group {
        min-width: 100px;
    }

        #product-detail .qty-group .qty-field {
        width: auto;
        min-width: 34px;
        max-width: 100px;
    }

    .home-merken > div {
        padding-left: 15px;
    }

    /* #product-detail > div > div > div {
        padding-left: 0px !important;
        padding-right: 0px !important;
    }*/

    .category-detail-main .category-filters-container {
        padding: 30px 15px;
    }

    .catprod {
        padding-left: 15px !important;
        padding-right: 15px !important;
    }

   /* .filter-mob {
        padding-top: 40px;
    }

    .category-detail-main > div > div.col-xl-9 {
        padding-top: 40px;
    }*/

    .filter-mob {
        padding-top: 20px;
    }

    .category-detail-main > div > div.col-xl-9 {
        padding-top: 20px;
    }

    .checkout-page .checkout-page-form {
        margin-top: 20px !important;
        margin-bottom: 20px !important;
    }

    .cart-totals-html-desktop {
        display: none;
    }

    .cart-totals-html-mobile {

    }

    .cart-totals-html-mobile .cart-totals {
        padding-left: 0px !important;
        padding-right: 0px !important;
    }

    .info-header > div {
        padding-bottom: 0px !important;
    }

    .category-detail-header {
        padding-top: 0px;
        padding-bottom: 20px;
    }

    .pl-5, .px-5 {
        padding-left: 15px !important;
    }

    .pr-5, .px-5 {
        padding-right: 15px !important;
    }

    .pl-md-5, .px-md-5 {
        padding-left: 15px !important;
    }

    .pr-md-5, .px-md-5 {
        padding-right: 15px !important;
    }


    .searchpage-content {
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-wrap: wrap;
        flex-wrap: wrap;
        -webkit-align-content: center;
        align-content: center;
        -webkit-align-items: center;
        align-items: center;
        justify-content: center;
        -webkit-justify-content: center;
        min-height: 500px;
    }


    .searchpage-content > div {
        padding-top: 40px;
        padding-bottom: 40px;
    }


    .over-ons-header h1, .contact-header h1, .algemene-voorwaarden-header h1, .disclaimer-header h1, .privacy-policy-header h1 {
        font-size: 40px;
        line-height: 60px;
    }

    .home-unieke-collectie-homewear {
        padding-top: 40px;
        padding-bottom: 40px;
    }

    .home-unieke-collectie-homewear-col2 > div {
        padding-top: 40px;
    }

    .privacyverklaring-main-content-table-desktop {
        display: none;
    }

    .home-usps h2, .home-categories-title h2, .webshop-categories-title h2 {
        font-size: 95.39px;
        line-height: 83.6px;
        letter-spacing: 8px;
    }

    .home-merken h2 {
        font-size: 79.76px;
        line-height: 68.91px;
        letter-spacing: 8px;
    }

    .home-usps h2 {
        margin-bottom: 0px;
    }

    .home-usps ul {
        padding-left: 0px;
        padding-right: 0px;
        margin-bottom: 0px;
    }

    .home-categories-section {
        padding-top: 40px;
        padding-bottom: 40px;
    }

    .home-ontspannen-genieten {
        padding-top: 40px;
        padding-bottom: 40px;
    }

    .home-ontspannen-genieten-row1 {
        padding-bottom: 20px;
    }

    .home-ontspannen-genieten-row2 {
        padding-top: 20px;
    }

    .home-categories img {
        max-height: 300px;
    }

    .home-categories-title + .container .col-lg-4 {
        margin-top: 30px;
    }


    .over-ons-rust {
        padding-top: 40px;
        padding-bottom: 40px;
    }

    .over-ons-kwaliteit {
        padding-top: 40px;
        padding-bottom: 20px;
    }

    .over-ons-kwaliteit > div > div > div:nth-child(2) {
        padding-top: 40px;
    }

    .over-ons-slider-section {
        padding-bottom: 40px;
    }

    .over-ons-contact {
        padding-top: 40px;
        padding-bottom: 40px;
    }

    .over-ons-contact-col2 {
        padding-top: 20px;
    }

 /*   .over-ons-header > div > div > div > div:nth-child(1), .contact-header > div > div > div > div:nth-child(1), .algemene-voorwaarden-header > div > div > div > div:nth-child(1), .disclaimer-header > div > div > div > div:nth-child(1), .privacy-policy-header > div > div > div > div:nth-child(1) {
        z-index: 1;
        position: absolute;
        top: 66%;
    }*/

    .over-ons-header > div > div > div > div:nth-child(1), .contact-header > div > div > div > div:nth-child(1), .algemene-voorwaarden-header > div > div > div > div:nth-child(1), .disclaimer-header > div > div > div > div:nth-child(1), .privacy-policy-header > div > div > div > div:nth-child(1) {
        left: 30px;
    }

    .over-ons-header img,
    .contact-header img,
    .algemene-voorwaarden-header img,
    .disclaimer-header img,
    .privacy-policy-header img {
        min-height: 300px;
        object-fit:cover;
    }


    .cart-contents .col-md-8 {
        padding-top: 40px;
    }

    .cart-page-cart-content .cart-totals {
        padding-left: 0px !important;
        margin-top: 40px;
    }

    .cart-page-cart-content .cart-content-main,
    .cart-page-cart-content .cart-content-proceed {
        padding-left: 15px;
        padding-right: 15px;
    }

    .cart-page-cart-content .cart-content-header {border-bottom: none;}


}


@media (min-width: 960px) {
    .container-fluid-mobile {
        display: none !important;
    }
}


@media (max-width: 959px) {


    .container-fluid-desktop {
        display: none !important;
    }

    .over-ons-slider-section .uk-slidenav {
        background: #FFF;
    }

}

@media (min-width: 640px) {
    .searchpage-content .search-field-input input {min-width: 400px;}
}

@media (min-width: 640px) and (max-width: 991px) {

    /*.home-usps ul li {
        padding-top: 20px;
    }*/

    .home-usps-col1, .home-usps-col2 {
        flex: 0 0 100%;
        max-width: 100%;
    }
}

@media (min-width: 768px) and (max-width: 900px) {
    .home-logos img {
        max-width: 150px;
    }
}

@media (max-width: 767px) {

    .home-contact-col2 {
        padding-top: 40px;
    }


/*    .home-logos img {
        max-width: 40vw;
    }*/
    .home-logos > div > div > div {
        text-align: center;
    }
    .home-logos > div > div > div > div {
        flex-basis: 33.333333%;
    }

    .home-logos > div > div > div > div {
        padding: 10px;
    }

    .home-header h1 {
        /*font-size: 44px;*/
        /*line-height: 56px;*/
        /*line-height: 48px;*/
        font-size: 36px;
        line-height: 40px;
    }


        .cart-page-cart-content .cart-page-cart-content-totals {
       /* flex: 0 0 50%;
        max-width: 50%;
        margin-left: auto;
        margin-right: 15px;*/
        margin-top: 40px;
    }

    .cart-page-cart-content .cart-page-cart-content-totals > div:nth-child(1) > div {
        margin-left: 0px;
        margin-right: 0px;
    }


    /*    header > div > div > div {
        text-align: center;
    }

*/
    .home-main > div > div > div:nth-child(2) {
        margin-top: 40px;
    }

    .home-bottom > div > div > div:nth-child(2) {
        margin-top: 40px;
    }

    section#related-products {
        padding-top: 0px !important;
    }

    .home-unieke-collectie-homewear-col2 > div,
    .home-warmte-in-de-huiskamer-row1-col1 > div,
    .home-ontspannen-genieten-row1-col1 > div,
    .home-ontspannen-genieten-row2-col2 > div,
    .home-warmte-in-de-huiskamer-row1-col1 > div,
    .home-ontspannen-genieten-row1-col1 > div,
    .home-warmte-in-de-huiskamer-row2-col2 > div,
    .home-ontspannen-genieten-row2-col2 > div,
    .home-verpakken-col1 > div,
    .over-ons-rust h2,
    .over-ons-rust p,
    .over-ons-kwaliteit h2,
    .over-ons-kwaliteit p {
        max-width: none;
    }

    .home-unieke-collectie-homewear-col2 > div {
        padding-left: 0px;
        paddding-right: 0px;
    }

    .over-ons-contact p {
        max-width: none;
    }

    .order-btn, .proceed-button a {
        padding: 10px 20px;
    }

    #product-detail .order-btn, #product-detail .proceed-button a, .proceed-button #product-detail a {
        padding: 0px 20px;
    }

    .home-merken h2 {
        font-size: 69.76px;
        line-height: 58.91px;
        letter-spacing: 4px;
    }

    .home-usps h2, .home-categories-title h2, .webshop-categories-title h2 {
        font-size: 65.39px;
        line-height: 53.6px;
        letter-spacing: 4px;
    }

    .contact-main > div > div > div:nth-child(2) {
        padding-top: 40px;
    }



    h2 {
        font-size: 32px;
        letter-spacing: 0px;
    }

    h3 {
        letter-spacing: 0px;
    }


    .algemene-voorwaarden-main h2, .disclaimer-main h2, .privacy-policy-main h2 {font-size: 24px;}
    .algemene-voorwaarden-main h3, .disclaimer-main h3, .privacy-policy-main h3 {font-size: 20px;letter-spacing: 0px;}

    .algemene-voorwaarden-main, .disclaimer-main, .privacy-policy-main, .contact-main {
        padding: 40px 10px;
        box-sizing: border-box;
    }

    #product-detail > div > div {
        padding-top: 40px !important;
        padding-bottom: 40px !important;
    }

    .cart-content-section {
        padding-top: 40px;
        padding-bottom: 40px;
    }

    .header-logo {
        position: relative;
        /*top: -28px;*/
        top: 0px;
        max-width: 220px;
    }


    .searchpage-content {
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-wrap: wrap;
        flex-wrap: wrap;
        -webkit-align-content: center;
        align-content: center;
        -webkit-align-items: center;
        align-items: center;
        justify-content: center;
        -webkit-justify-content: center;
        min-height: 300px;
    }

    .over-ons-header h1, .contact-header h1, .algemene-voorwaarden-header h1, .disclaimer-header h1, .privacy-policy-header h1 {
        font-size: 24px;
        line-height: 44px;
    }

    .home-categories .txt {
        padding: 10px;
        font-size: 24px;
    }


    .footer-col1, .footer-col2 {
        flex: 0 0 50%;
        max-width: 50%;
    }

    .footer-col3 {
        flex: 0 0 100%;
        max-width: 100%;
        justify-content: flex-start;
        -webkit-justify-content: flex-start;
    }

    .footer-col1, .footer-col2, .footer-col3, .footer-col4 {
        flex: 0 0 100%;
        max-width: 100%;
        justify-content: center;
        -webkit-justify-content: center;
        text-align: center !important;
    }

    .home-unieke-collectie-homewear img {
        position: relative;
        top: 0%;
        left: 0%;
        -webkit-transform: translate(0%, 0%);
        -moz-transform: translate(0%, 0%);
        -ms-transform: translate(0%, 0%);
        -o-transform: translate(0%, 0%);
        transform: translate(0%, 0%);
        z-index: 2;
        max-height: 662px;
        max-width: 557px;
        object-fit: cover;
    }

    .home-ontspannen-genieten-row1-col2 img {
        position: relative;
        top: 0%;
        right: 0%;
        -webkit-transform: translate(0%, 0%);
        -moz-transform: translate(0%, 0%);
        -ms-transform: translate(0%, 0%);
        -o-transform: translate(0%, 0%);
        transform: translate(0%, 0%);
        z-index: 2;
        max-height: 674px;
        max-width: 577px;
        object-fit: cover;
    }

    .home-ontspannen-genieten-row2-col1 img {
        position: relative;
        top: 0%;
        left: 0%;
        -webkit-transform: translate(0%, 0%);
        -moz-transform: translate(0%, 0%);
        -ms-transform: translate(0%, 0%);
        -o-transform: translate(0%, 0%);
        transform: translate(0%, 0%);
        z-index: 2;
        max-height: 577px;
        max-width: 465px;
        object-fit: cover;
    }

    .home-verpakken-col2 img {
        position: relative;
        top: 0%;
        right: 0%;
        -webkit-transform: translate(0%, 0%);
        -moz-transform: translate(0%, 0%);
        -ms-transform: translate(0%, 0%);
        -o-transform: translate(0%, 0%);
        transform: translate(0%, 0%);
        z-index: 2;
        max-height: 674px;
        max-width: 577px;
        object-fit: cover;
    }

    .over-ons-rust-col2 img {
        position: relative;
        top: 0%;
        left: 0%;
        -webkit-transform: translate(0%, 0%);
        -moz-transform: translate(0%, 0%);
        -ms-transform: translate(0%, 0%);
        -o-transform: translate(0%, 0%);
        transform: translate(0%, 0%);
        max-height: 662px;
        max-width: 557px;
        object-fit: cover;
    }

    .over-ons-contact-col2 img {
        position: relative;
        top: 0%;
        left: 0%;
        -webkit-transform: translate(0%, 0%);
        -moz-transform: translate(0%, 0%);
        -ms-transform: translate(0%, 0%);
        -o-transform: translate(0%, 0%);
        transform: translate(0%, 0%);
        max-height: 674px;
        max-width: 100%;
        object-fit: cover;
    }

    .home-ontspannen-genieten-row2 > div:nth-child(1) {order: 2;-webkit-order: 2;}
    .home-ontspannen-genieten-row2 > div:nth-child(2) {order: 1;-webkit-order: 1;}

    .home-warmte-in-de-huiskamer-row2 > div:nth-child(1) {order: 2;-webkit-order: 2;}
    .home-warmte-in-de-huiskamer-row2 > div:nth-child(2) {order: 1;-webkit-order: 1;}

    .home-warmte-in-de-huiskamer-row1-col1 > div, .home-ontspannen-genieten-row1-col1 > div {
        padding: 0px;
    }

    .home-categories-title + .container .col-lg-4 {
        margin-top: 30px;
    }

    .home-categories-title + .container .col-lg-4 {
        margin-top: 20px;
    }

    .home-warmte-in-de-huiskamer {
        padding-top: 40px;
        padding-bottom: 40px;
    }

    .home-warmte-in-de-huiskamer-row1-col2 {
        text-align: center;
        padding-top: 40px;
    }

    .home-ontspannen-genieten-row1-col2 {
        padding-top: 20px;
    }

    .home-warmte-in-de-huiskamer-row2 > div:nth-child(2) {
        padding-top: 40px;
    }

    .home-warmte-in-de-huiskamer-row2-col2 > div, .home-ontspannen-genieten-row2-col2 > div {
        padding: 0px;
    }

    .home-warmte-in-de-huiskamer-row2 > div:nth-child(1) {
        padding-top: 20px;
    }

    .home-verpakken {
        padding-top: 40px;
        padding-bottom: 40px;
    }

    .home-verpakken-col1 > div {
        padding: 0px;
    }

    .home-verpakken-col2 {
        padding-top: 20px;
    }

    .cart-contents .col-md-8 {
        padding-top: 40px;
    }

    .cart-page-cart-content .cart-totals {
        padding-left: 0px !important;
        margin-top: 40px;
    }

    .cart-page-cart-content .cart-content-main,
    .cart-page-cart-content .cart-content-proceed {
        padding-left: 15px;
        padding-right: 15px;
    }

    .cart-page-cart-content .cart-content-header {border-bottom: none;}

    /*.home-categories .col-lg-8 .row + .row {
        margin-top: 0px;
    }

    .home-categories .col-lg-8 .row:nth-child(2) > div:nth-child(2) {
        margin-bottom: 20px;
    }

    .home-categories-title + .container .col-lg-4 {
        margin-top: 0px;
    }*/

    .home-unieke-collectie-homewear img,
    .home-ontspannen-genieten-row1-col2 img,
    .home-ontspannen-genieten-row2-col1 img,
    .home-warmte-in-de-huiskamer-row1 img,
    .home-warmte-in-de-huiskamer-row2 img,
    .home-verpakken-col2 img,
    .over-ons-rust-col2 img,
    .over-ons-kwaliteit img {
        max-width: none;
    }


}

@media (max-width: 750px) {
    .home-categories ul li {display: block;}
    .home-categories ul li + li {padding-top: 15px;}
}

@media (max-width: 699px) {
    .form-steps li {
        margin-right: 0px;
        display: block;
    }

    .form-steps li + svg, .form-steps li + i {
        display: none;
    }
}


@media (max-width: 639px) {

    .detail-tab-title a {
        padding: 20px 30px;
        font-size: 18px;
        line-height: 22px;
    }

    .info-header .header-logo {
        max-width: 120px;
    }

    .info-header > div > div > div:nth-child(1) {
        padding-right: 0px;
    }

    .info-header > div > div > div:nth-child(2) {
        padding-left: 0px;
    }

    .header-icons .search-field {
        margin-left: 15px;
        margin-right: 15px;
    }


    .home-usps ul li {
        /*    padding-top: 15px;
    padding-bottom: 15px;*/
        padding-top: 5px;
        padding-bottom: 5px;
        padding-right: 20px;
        /*display: block;*/
    }

    .home-header-logo > div {
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-wrap: wrap;
        flex-wrap: wrap;
        -webkit-align-content: center;
        align-content: center;
        -webkit-align-items: center;
        align-items: center;
        justify-content: flex-start;
        -webkit-justify-content: flex-start;
    }


}


@media (max-width: 600px) {
    .checkout-page .butn-next {
        width: 100%;margin-bottom: 30px;
    }
    .checkout-page .butn-light {
        width: 100%;
    }

    .catprod {
        margin-bottom: 15px !important;
    }

    .category-detail-main .product-info {
        padding-bottom: 15px !important;
    }

}


@media (max-width: 520px) {
    .detail-tab-title a {
        padding: 20px 10px;
        font-size: 16px;
        line-height: 22px;
    }
}


.qty-add-to-cart p a {
    color: #74C6C7;
}

.qty-add-to-cart p a:hover,
.qty-add-to-cart p a:focus {
    color: #2B2B2B;
    text-decoration: none;
}

.butn-login {
    width: 100%;
}

.butn-login:hover,
.butn-login:focus {
    background-color: #2B2B2B !important;
}

.butn-login-request {
    width: auto;
    background-color: #74C6C7;
    color: #FFF;
}

.butn-login-request:hover,
.butn-login-request:focus {
    background-color: #2B2B2B;
    color: #FFF;
}

.password-request-link {
    color: #2B2B2B;
    display: block;
    margin-top: 15px;
}

.password-request-link:hover,
.password-request-link:focus {
     color: #74c6c7;
    text-decoration: none;
}


@media (max-width: 480px) {



    html, body {
        font-size: 18px;
        line-height: 28px;
    }

    .privacy-policy-main p,
    .privacy-policy-main li,
    .privacy-policy-main td,
    .privacy-policy-main p a,
    .privacy-policy-main li a,
    .privacy-policy-main td a {
        font-size: 16px;
    }

    .home-logos > div > div > div > div {
        padding: 10px;
    }

    /*.home-logos > div > div > div > div + div {
        padding-top: 15px;
    }*/

    h2,
    .home-main h2,
    .home-main h2 span,
    .home-popular-products h2,
    .home-popular-products h2 span,
    .page-contact h2,
    .home-bottom h2 {
        font-size: 26px;
        line-height: 33px;
    }

    .page-contact h2, .page-offerteaanvraag h2 {
        font-size: 24px;
        line-height: 31px;
    }

    .home-usps ul li::before, .over-ons-usps ul li::before, .categorie-top-usps ul li::before, .product-top-usps ul li::before {
        position: absolute;
        top: 0%;
        left: 0%;
        -webkit-transform: translate(50%, 50%);
        -moz-transform: translate(50%, 50%);
        -ms-transform: translate(50%, 50%);
        -o-transform: translate(50%, 50%);
        transform: translate(50%, 50%);
    }

    /*.home-categories ul li {
        display: block;
        padding-left: 70px;
    }*/


    header .container-fluid-mobile > div > div:nth-child(1),
    header .container-fluid-mobile > div > div:nth-child(2) {
        flex: 0 0 100%;
        max-width: 100%;
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-wrap: wrap;
        flex-wrap: wrap;
        -webkit-align-content: center;
        align-content: center;
        -webkit-align-items: center;
        align-items: center;
        justify-content: space-around;
        -webkit-justify-content: space-around;
    }

 /*   header .container-fluid-mobile > div > div:nth-child(2) {
        padding-top: 40px;
    }*/

    header .container-fluid-mobile > div > div:nth-child(2) {
        padding-top: 25px;
    }

    .menu-container-mobile + .main-nav-container {
        padding-top: 0px !important;
    }

    header .search-field,
    header .mini-cart {top: 0px;left: auto;}

    header .search-field {
        margin-right: 0;
    }

    .detail-tab-title a {
        padding: 20px 8px;
        font-size: 15px;
        line-height: 22px;
    }

    .header-home .header-icons .menutext {
        display: block;
    }

    .home-categories .col-lg-8 .row + .row {
        margin-top: 0px;
    }

    .home-categories .col-lg-8 .row:nth-child(2) > div:nth-child(2) {
        margin-bottom: 20px;
    }

    .home-categories-title + .container .col-lg-4 {
        margin-top: 20px;
    }

    .home-categories-title + .container > .row > div > .row {
        margin-top: 0px !important;
    }

    .home-categories-section > div > div > div > section > div:nth-child(2) > div > div.col-lg-8 > div:nth-child(2) {
        margin-top: 0px !important;
    }

    .home-categories .col-lg-8 .row:nth-child(2) > div:nth-child(1) {
        order: 2;-webkit-order: 2;
    }

    .home-categories .col-lg-8 .row:nth-child(2) > div:nth-child(2) {
        order: 1;-webkit-order: 1;
    }

    .alert .btn {
        margin-left: auto !important;
        margin-right: auto !important;
        margin-top: 30px !important;
        display: block;
    }

    #product-detail .product-usps > div {
        padding-left: 0px;
        padding-right: 0px;
    }

    #product-detail .product-usps ul li {
        font-size: 15px;
    }

    .qty-add-to-cart > div {
        max-width: none;
        width: 100%;
    }

    #product-detail .qty-add-to-cart .col-5 {
        flex: 0 0 100%;
        max-width: 100%;
    }

    #product-detail .qty-add-to-cart .col-7 {
        flex: 0 0 100%;
        max-width: 100%;
        margin-top: 30px;
    }


    /*  .header-logo {
        position: relative;
        top: -45px;
        max-width: 180px;
        left: -15px;
    }*/

    .header-home .header-logo {
        position: relative;
        top: -50px;
        max-width: 177px;
        left: -45px;
    }

    .header-home > div.py-4 {
        padding-top: 40px !important;
        padding-bottom: 60px !important;
    }

    .header-home #offcanvas-menu .header-logo {
        position: relative;
        top: 0px;
        max-width: 177px;
        left: 0px;
    }


    .info-header #offcanvas-menu .header-logo {
        position: relative;
        top: 0px;
        max-width: 177px;
        left: 0px;
    }

    .footer-col1, .footer-col2 {
        flex: 0 0 100%;
        max-width: 100%;
    }
    .footer-col2 {
        justify-content: flex-start;
        -webkit-justify-content: flex-start;
        /*margin-top: 2.5rem !important;*/
        margin-top: 0 !important;
    }

    .home-categories-section .home-categories .col-lg-8 .col-6 {
        flex: 0 0 100%;
        max-width: 100%;
    }

    .home-categories-section .home-categories .brown-bg {
        margin-bottom: 20px;
    }

    .home-categories-section .home-categories .darkgrey-bg {
        margin-top: 20px;
    }

    .searchpage-content .search-field-input,
    .searchpage-content .do-search-button {
        display: block;
        width: 100%;
    }

    .searchpage-content .search-field-input input {
        min-width: auto;
        width: 100%;
    }


}
