
header {
    position: sticky;
    top:0;
    z-index: 99999;
   /* background-color: $beige;*/
    background-color: #FFF;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.mini-cart {
    position: relative;
    a, a.visited {
        color: $main-color;
    }
    .badge-main {
        background-color: $pastel-2;
        position: absolute;
        right: -2px;
        top: -5px;
        color: $black;
        z-index: 999;
    }
}
