.product-list-item {
    position: relative;
    border: 1px solid $pastel-1;
    height: 100%;
    display: block;
    color: $font-color;
    text-decoration: none !important;
    background-color: $white;

    .new-label {
        position: absolute;
        top: 0;
        display: block;
        z-index: 10;
        background-color: $pastel-2-accent;
        padding: 2px 10px 2px 10px;
        color: #ffffff;

    }
    .sale-label {
        position: absolute;
        top: 0;
        display: block;
        z-index: 10;
        background-color: $pastel-2-accent;
        padding: 2px 10px 2px 10px;
        color: #ffffff;

    }

    &:before {
        position: absolute;
        content: "";
        display: block;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: 1px solid $pastel-1-accent;
        z-index: 4;
        opacity: 0;
        transition: all 500ms ease;
    }


    .image-container {
        position: relative;
        padding-bottom: 145%;
        overflow: hidden;
        background-color: $white;
        img {
            position: absolute;
            top:0;
            left:0;
            height: 100%;
            width: 100%;
            object-fit: cover;
        }
    }


    .product-info {
        hyphens: auto;
        &:before {
            content: "";
            position: absolute;
            top: 0;
            left: 15px;
            width: calc(100% - 30px );
            height: 100%;
            opacity:1;
            transition: all 400ms ease;
            background-color: $pastel-1;
        }
        position: relative;

        .product-title {
            margin-bottom: 0;
            font-size: 1rem;
            font-weight: normal;
            @include media-breakpoint-down(md) {
                font-size: 1rem;
            }
        }
        .product-price {
            font: {
                family: $main-font;
                size: 18px;
                weight: bold;
            }
            color: $black
        }
    }
    &:hover {
        color: $font-color;
        background-color: #f9f5ee;
        .product-info {
            &:before {
                background-color: $pastel-1-accent;
            }
        }
        &:before, &:after {
            width: 100%;
            height: 100%;
            opacity: 1;
        }

    }
    .open-icon {
        width: 20px;
    }
}

.coming-soon {
    h2 {
        display: block;
        font-size: 2.5rem;
        &:after {
            display: block;
            content: url('/images/curl.svg');
            width: 200px;
            margin: 20px auto;
        }
    }
}
