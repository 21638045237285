body {
    font-family: $main-font;
    color: $black;
}

.uk-button.uk-button-default {
    width: 271px;
    padding-top:10px;
    padding-bottom: 10px;
    border-radius: 7px;
    background-color: $white;
    transition: all 300ms ease-in-out;
    font-size: 18px;
    &:hover {
        background-color: $black;
        color: $white;
    }
}
.uk-button.btn-dark {
    background-color: $main-color;
    color: $white;
}

input:not([type="checkbox"]):not([type="radio"]) {
    /*border: 1px solid $pastel-3-accent;*/
    /*border: 1px solid $brown-v1;*/
    border: 1px solid #C4C4C4;
    background-color: transparent;
    padding-top: 5px;
    padding-bottom: 5px;

}

.uk-select:not([multiple]):not([size]) {
    -webkit-appearance: none;
    -moz-appearance: none;
    padding-right: 20px;
    background-image: url("data:image/svg+xml;utf8,<svg viewBox='0 0 140 140' width='16' height='16' xmlns='http://www.w3.org/2000/svg'><g><path d='m121.3,34.6c-1.6-1.6-4.2-1.6-5.8,0l-51,51.1-51.1-51.1c-1.6-1.6-4.2-1.6-5.8,0-1.6,1.6-1.6,4.2 0,5.8l53.9,53.9c0.8,0.8 1.8,1.2 2.9,1.2 1,0 2.1-0.4 2.9-1.2l53.9-53.9c1.7-1.6 1.7-4.2 0.1-5.8z' fill='black'/></g></svg>");
    background-repeat: no-repeat;
    background-position: 94% 50%;
    /*border: 1px solid $pastel-3-accent;*/
    border: 1px solid $brown-v1;
    background-color: transparent;
    padding-top: 5px;
    padding-bottom: 5px;
}

.select2-container {
    width: 100% !important;
    .selection {
        height: 38px;
        .select2-selection {
            height: 38px;
            @extend .form-control;
            /*border: 1px solid $pastel-3-accent;*/
            border: 1px solid $brown-v1;
            border-radius: 0;
            background-color: transparent !important;
            padding-top: 5px;
            padding-bottom: 5px;
        }
         .select2-selection__arrow {
             height: 38px !important;
         }
    }
}

.price-input  {
    position: relative;
    &:before {
        position: absolute;
        content: "€";
        top: 8px;
        left: 22px;
        /*color: $pastel-3-accent;*/
        color: $brown-v1;
    }
    input {
        text-align: right;
    }
}
.break {
    flex-basis: 100%;
    height: 0;
}

.qty-selector {
    position: relative;
    .qty-select-open {
        height: 45px;
        width: 45px;
        /*background-color: $pastel-3;
        border: 1px solid $pastel-3;*/
        background-color: $brown-v1;
        border: 1px solid $brown-v1;
        color: $white;
        text-align: center;
        margin-left: -4px;
        padding: 10px 0;
        cursor: pointer;
    }
    .qty-field {
        /*border: 1px solid $pastel-3;*/
        border: 1px solid $brown-v1;
        height: 45px; width: 80px;
        text-align: center;
        vertical-align: top;

    }
    .qty-select-options {
        position: absolute;
        left: 0;
        z-index: 99;
        top: 100%;
        background-color: $white;
        /*border: 1px solid $pastel-3;*/
        border: 1px solid $brown-v1;
        border-top: none;
        width: 125px;
        height: 200px;
        overflow-y: auto;
        transform: scaleY(0);
        transform-origin: top;
        opacity: 0;
        transition: all 300ms ease-in-out;

        &.open {
            transform: scaleY(1);
            opacity: 1;

        }
        /* width */
        &::-webkit-scrollbar {
            width: 10px;
        }

        /* Track */
        &::-webkit-scrollbar-track {
            background: #f1f1f1;
        }

        /* Handle */
        &::-webkit-scrollbar-thumb {
            /*background: $pastel-3;*/
            background: $brown-v1;
        }

        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
           /* background: $pastel-3;*/
            background: $brown-v1;
        }
        scrollbar-width: thin;
        /*scrollbar-color: $pastel-3 #f1f1f1;;*/
        scrollbar-color: $brown-v1 #f1f1f1;
        div {
            &:hover {
                /*background-color: rgba($pastel-3, .6);*/
                background-color: rgba($brown-v1, .6);
            }
        }
    }
}
